Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "scheduling";
exports.labelBodyText = "scheduling Body";

exports.btnExampleTitle = "CLICK ME";
exports.getApiMethodType = "GET";
exports.applicationJsonApiContentType = "application/json";
exports.serviceProviderAPiEndPoint = "scheduling/service_provider_schedulings/get_sp_details";
exports.gradeOfCertificateEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Grade of certificate";
exports.categoryEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Category";
exports.airPortClassEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Class";
exports.aircraftTypeEndPoint = "bx_block_automatedprioritisation/service_sub_categories_for_category/Aircraft";
exports.getAirportListEndPoint = "/bx_block_automatedprioritisation/service_sub_categories_for_category/Airport";
// Customizable Area End