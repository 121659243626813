import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import {add, checkedBox, remove, unCheckedBox,} from "./assets"
import { createRef } from "react";
import { isEqual } from "lodash";
const NotifyUsers = [
  {
    name: "James Cooper",
    id: 1,
    image: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
  },
  {
    name: "Mia Sullivan",
    id: 2,
    image: "",
  },
  {
    name: "Benjamin Rivera",
    id: 3,
    image: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
  },
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  date?: any
  // Customizable Area End
}
interface CustomToolbarProps {
  label: string;
  onView: (view: string) => void;
  views: string[];
}
interface User {
  id: number;
  name: string;
  avatar: string;
  calendar: string;
}

interface Availability {
  id: number;
  start_time: string;
  end_time: string;
  availabilityAirport: string,
  availabilityTests: string;
  availability_date: string;
  available_slots_count: number;
  time_zone: string;
  status: string;
}

export interface Aircraft {
  id: string;
  name: string;
  selected: boolean;
}

export interface IAirport {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string
}

export interface IDesignations {
  id: number;
  service_subcategory_id: number;
  service_subcategory_name: string
}

interface InitialFilters {
  grades: Aircraft[],
  classes: Aircraft[],
  categories: Aircraft[],
  aircrafts: Aircraft[],
  airports: Aircraft[],
}

interface AvailabilityData {
  availability: Availability | null;
  airports: any[];
  designations: any[];
}
type AlertType = 'success' | 'error' | 'warning' | 'info';
interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  availability:any;
  filteredData:any;
  filteredData1:any;

  events: any,
  details: any;
  token: any;
  date: any,
  view: any,
  open:boolean;
  isCalenderOpen:boolean;
  isSelectAirportOpen:boolean;
  isSelectTestTypeOpen:boolean;
  isNotifiyAll:boolean;
  availabilityDate: string;
  timeSlot: { starttime: string; endtime: string }[];
  selectedTimeSlotForEdit: { starttime: string; endtime: string  };
  timeZones: any[];
  selectedTimeZone: string;
  showtimeZoneOverlay: boolean;
  currentDate:any;
  isSetDetailsOpen: boolean;
  isNotifyContainerOpen: boolean;
  allAirports: any[];
  userSelectedAirports: any[];
  allTests: any[];
  userSelectedTests: any[];
  allMembers: any[];
  shouldNotifyAlluser:boolean;
  alertType: AlertType;
  alertMsg: string;
  isAlert: boolean;
  startDate: any, // Start of the current month
  endDate:any, // End of the current month
  userSelectedMembers: any[];
  isViewAvailabiliDialogOpen: boolean;
  selectedAvailability: AvailabilityData;
  isEditAvailabilityOpen:boolean;
  deleteAvailabilityDialogOpen: boolean;
  openDialog: boolean;
  editSelectedTests: any[];
  editselectedAirports: any[];
  availabilityId: number;
  isFiltersDialogOpen: boolean;
  anchorEl: any;
  isDpePopupOpen: any,
  anchorElDpe: any,

  isTestPopupOpen: boolean;
  isAircraftPopupOpen: boolean;
  isAirportPopupOpen: boolean;
  isGradePopupOpen: boolean,
  isCategoryPopupOpen: boolean,
  isClassPopupOpen: boolean,

  // Category data
  allGradeData: any;
  filteredGradeData: any;
  allCategoryData: any;
  filteredCategoryData: any;
  allClassData: any;
  filterClassData: any;

  // Aircrafts
  allAircrafts: Aircraft[];
  filteredAircrafts: Aircraft[];
  searchQuery: string;
  // Airports
  allAirportsForFilter: Aircraft[];
  filteredAirports: Aircraft[];
  searchQueryAirport: string;

  isResetEnabled: boolean;
  todayDate: string;
  selectedd: number[]; // Stores selected user IDs
  selectAll: any;
  initialFilters: InitialFilters;
  userRole: string;

  isViewApplicantsAvailabilityDialogOpen: boolean;
  isTermsChecked: boolean;
  isDpeTestTypeOpen: boolean;

  dpeAllTest: any[];
  depSelectedTests: IDesignations;

  isDpeAirportSelectOpen: boolean;
  dpeAllAirport: any[];
  depSelectedAirport: IAirport;

  dpeAvailabilityDate: string;
  dpeTimezone: string;
  dpeTimeslot: string;
  isLoading: boolean;
  dpeAvailabilityStatus: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  availabilityApiCallId: string="";
  filterGetApiCallId: string="";
  filterGetApiCallId4: string="";
  availabilityApiCallId1: string=";"
  availabilityApiCallId5: string=";"
  availabilityApplicantApiCallId: string="";
  serviceProviderDetailApiId: any;
  serviceProviderScheduleApiId: any;
  saveAvailablityApiCallId: string = "";
  getAirportsApiCallId: string = "";
  getAllSelectedTestsApiCallId: string = "";
  fetchAvailabilityDetailsApiCallId: string = "";
  deleteAvailabilityApiCallId: string = "";
  editAvailabilityDetailsApiCallId: string = "";
  buttonRef: any
  getCategoryApiCallId: string = "";
  getAirPortsForFilterApiCallId: string = "";
  getCertificateApiCallId: string = "";
  getClassApiCallId: string = "";
  getAircrafttypeApiCallId: string = "";
  fetchSingleAvailibilityForDpeApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.buttonRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      availability:[],
      filteredData:[],
      filteredData1:[],

      details: {},
      token: null,
      date: new Date(),
      view: "month",
      open:false,
    availabilityDate: new Date() as any,
    isCalenderOpen:false,
    isSelectAirportOpen:false,
    isSelectTestTypeOpen:false,
    isNotifiyAll:false,
    timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
    selectedTimeSlotForEdit: { starttime: "10:00", endtime: "11:00" },
    timeZones: [
      "Eastern Standard Time (EST)",
      "Central Standard Time (CST)",
      "Mountain Standard Time (MST)",
      "Pacific Standard Time (PST)",
      "Alaska Standard Time (AKST)",
      "Hawaii-Aleutian Standard Time (HST)",
    ],
    selectedTimeZone: "Eastern Standard Time (EST)",
    showtimeZoneOverlay: false,
    currentDate:new Date(),
    isSetDetailsOpen: false,
    isNotifyContainerOpen:false,
    allAirports: [],
    userSelectedAirports: [],
    allTests: [],
    userSelectedTests: [],
    allMembers: NotifyUsers,
    shouldNotifyAlluser:true,
    alertType: 'success'  as AlertType,
    alertMsg: '',
    isAlert: false,
    isViewAvailabiliDialogOpen: false,
    selectedAvailability:{airports:[], availability:null, designations:[]},
    isEditAvailabilityOpen:false,
    userSelectedMembers: [NotifyUsers[0], NotifyUsers[1]],
    deleteAvailabilityDialogOpen: false,
    openDialog:false,
    startDate: moment().startOf("month").toDate(), // Start of the current month
    endDate: moment().endOf("month").toDate(), // End of the current month
    events: [],
    availabilityId: 0,
    editselectedAirports: [],
    editSelectedTests: [],
    isFiltersDialogOpen: false,
    anchorEl: null,
    isDpePopupOpen: false,
    anchorElDpe: null,

    isTestPopupOpen: false,
    isAircraftPopupOpen: false,
    isAirportPopupOpen: false,
    isGradePopupOpen: false,
    isCategoryPopupOpen: false,
    isClassPopupOpen: false,
    allGradeData: [],
    filteredGradeData: [],
    allCategoryData: [],
    filteredCategoryData: [],
    allClassData: [],
    filterClassData: [],
    allAircrafts: [],
    filteredAircrafts:  [],
    searchQuery: "",
    allAirportsForFilter: [],
    filteredAirports: [],
    searchQueryAirport: "",
    isResetEnabled: false,
    todayDate: moment().format("YYYY-MM-DD"),
    initialFilters: {
      aircrafts: [],
      airports: [],
      categories: [],
      classes: [],
      grades: [],
    },
    userRole: "",
    selectedd: [], // Stores selected user IDs
    selectAll: false,
    isViewApplicantsAvailabilityDialogOpen: false,
    isTermsChecked: false,
    isDpeTestTypeOpen: false,
    dpeAllTest: [],
    depSelectedTests: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" },
    isDpeAirportSelectOpen: false,
    dpeAllAirport: [],
    depSelectedAirport: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" },
    dpeAvailabilityDate: "",
    dpeTimeslot: "",
    dpeTimezone:"",
    isLoading: false,
    dpeAvailabilityStatus: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: this.state.selectedDate,
        token,
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.availabilityApiCallId) {
        this.setState({ availability: responseJson }, () => {
          this.transformEvents(); 
        });

      }
      if (apiRequestCallId === this.filterGetApiCallId) {
        this.setState({ filteredData: responseJson.data });
      }

      if (apiRequestCallId === this.availabilityApiCallId1) {
        this.setState({ filteredData1: responseJson });
        console.log('filteredData1',this.state.filteredData1)
      }

      if (apiRequestCallId === this.availabilityApplicantApiCallId) {
        this.setState({ availability: responseJson }, () => {
          this.transformEvents(); 
        });
        
      }

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ serviceProviderSchedule: [] });

        if (typeof responseJson.errors === "string") {
          this.showAlert("", responseJson.errors);
        } else {
          this.parseApiErrorResponse(responseJson);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else if (apiRequestCallId != null) {
         this.handleApiResponse(apiRequestCallId, responseJson)
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      runEngine.debugLog("Availability Message Received", message);
      const serviceProviderIdMessage = message.getData(
        getName(MessageEnum.ServiceProviderIdMessage)
      );
      if (serviceProviderIdMessage) {
        this.setState({
          serviceProviderId: serviceProviderIdMessage,
        });
      }

      const CalendarProviderDetailsApiMessage = message.getData(
        getName(MessageEnum.CalendarProviderDetailsApiMessage)
      );
      if (CalendarProviderDetailsApiMessage) {
        this.getServiceProviderDetails({
          ...CalendarProviderDetailsApiMessage,
          setApiCallId: "serviceProviderDetailApiId",
        });
        return;
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // this.filterGet()
    this.getToken();
    this.getAirportsRequest()
    this.getAllSelectedTestsByDPE()
    this.getCertificateApi()
    this.getCategoryApi()
    this.getAirPortClassApi()
    this.getAircrafttypeApi()
    this.getAirPortsApi()
    const currentDate = new Date(); // Get the current date
    this.calculateMonthDates(currentDate); 
    const role = localStorage.getItem('role');
    this.setState({userRole: role as string})

    this.handleBackToViewAvailability()
  }

  calculateMonthDates = (date: Date) => {

    const startDate = moment(date).startOf("month").toDate();
    const endDate = moment(date).endOf("month").toDate();
    this.setState({ startDate, endDate }, () => {
      const role = localStorage.getItem('role');

      if (role === 'dpe') {
        this.availabilityGet(startDate, endDate);
      } else if (role === 'applicant') {
          this.availabilityApplicant(startDate, endDate);
      } else {
          console.log('Role not recognized');
      }
     
    });
  };

  handleApiResponse(apiRequestCallId: string, responseJson: any) {
    const apiHandlers = {
      [this.serviceProviderScheduleApiId]: () => {
        this.setState({
          serviceProviderSchedule:  responseJson && responseJson.data ? responseJson.data : "",
        });
      },
      [this.getAirportsApiCallId]: () => {
        this.getAirportsSucessCallBack(responseJson);
      },
      [this.getAllSelectedTestsApiCallId]: () => {
        this.getTestsSucessCallBack(responseJson);
      },
      [this.saveAvailablityApiCallId]: () => {
        this.getSaveAvailabilitySucessCallBack(responseJson);
      },
      [this.editAvailabilityDetailsApiCallId]: () => {
        this.updateAvailabilitySuccessCallBack(responseJson);
      },
      [this.fetchAvailabilityDetailsApiCallId]: () => {
        this.getAvailabilitySucessCallBack(responseJson);
      },
      [this.deleteAvailabilityApiCallId]:() => {
        this.deleteAvailabilitySuccessCallBack(responseJson);
      },
      [this.getCategoryApiCallId]:() => {
        this.getCategorySucessCallBack(responseJson);
      },
      [this.getAirPortsForFilterApiCallId]:() => {
        this.getAirportsFiltersSucessCallBack(responseJson);
      },
      [this.getCertificateApiCallId]:() => {
        this.getCertificateSucessCallBack(responseJson);
      },
      [this.getClassApiCallId]:() => {
        this.getAirPortClassSucessCallBack(responseJson);
      },
      [this.getAircrafttypeApiCallId]:() => {
        this.getAircrafttypeSucessCallBack(responseJson);
      },
      [this.fetchSingleAvailibilityForDpeApiCallId]: () => {
        this.getAvailabilityForDpeSuccessCallBack(responseJson)
      },
      [this.serviceProviderDetailApiId]: () => {
        let msg = new Message(getName(MessageEnum.NavigationCalendarMessage));
        msg.addData(
          getName(MessageEnum.CalendarProviderDetailsResponseMessage),
          responseJson
        );
        this.send(msg);
        this.unsubscribeMessages();
      },
    };
  
    if (apiHandlers[apiRequestCallId]) {
      apiHandlers[apiRequestCallId]();
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const { details } = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0,
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate),
  };

  formatDate = (date: Date = new Date()) => {
    return moment(date).format("dddd, MMMM D, YYYY");
  };

  handleAllCheckIcon() {
    if (
      this.state.userSelectedAirports.length === this.state.allAirports.length 
      && this.state.userSelectedAirports.length > 0 
      && this.state.allAirports.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }


  handleAllCheckIconEdit() {
    if (
      this.state.editselectedAirports.length === this.state.allAirports.length 
      && this.state.editselectedAirports.length > 0 
      && this.state.allAirports.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleAllTestCheckIconEdit() {
    if (
      this.state.editSelectedTests.length === this.state.allTests.length 
      && this.state.editSelectedTests.length > 0 
      && this.state.allTests.length
    ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  onCloseNewAvailabilityDialog(){
    this.setState({ open: false })
    this.handleCancel()
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  getColorForNotifyUser() {
    if (this.state.userSelectedMembers.length > 0 && !this.state.isNotifyContainerOpen) {
      return "#0F172A";
    }
    return this.state.isNotifyContainerOpen ? "#0074BF" : "#475569";
  }


  toggleCalenderOpen() {
    this.setState({
      isCalenderOpen: !this.state.isCalenderOpen,
      showtimeZoneOverlay: false
    });
  }

  toggleShowtimeZoneOverlay() {
    this.setState({
      showtimeZoneOverlay: !this.state.showtimeZoneOverlay,
      isCalenderOpen: false
    });
  }

  toggleSelectAirport() {
    this.setState({
      isSelectAirportOpen: !this.state.isSelectAirportOpen,
      isSelectTestTypeOpen: false,
      isNotifyContainerOpen: false,
    });
  }

  toggleSelectTest() {
    this.setState({
      isSelectTestTypeOpen: !this.state.isSelectTestTypeOpen,
      isSelectAirportOpen: false,
      isNotifyContainerOpen: false,
    });
  }

  toggleDpeSelectTest() {
    this.setState({
      isDpeTestTypeOpen: !this.state.isDpeTestTypeOpen,
      isDpeAirportSelectOpen: false
    });
  }

  toggleDpeSelectAirport() {
    this.setState({
      isDpeAirportSelectOpen: !this.state.isDpeAirportSelectOpen,
      isDpeTestTypeOpen: false
    });
  }


  toggleNotifyUserContainer() {
    this.setState({
      isNotifyContainerOpen: !this.state.isNotifyContainerOpen,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
    });
  }

  handleAddTimeSlot() {
    this.setState((prevState) => ({
      timeSlot: [
        ...prevState.timeSlot,
        { starttime: "10:00", endtime: "11:00" },
      ],
    }));
  }

  handleDelete(indexToDelete: number) {
    this.setState((prevState) => ({
      timeSlot: prevState.timeSlot.filter(
        (_, index) => index !== indexToDelete
      ),
    }));
  }

  handleUpdateTimeSlot = (
    index: number,
    key: keyof S["timeSlot"][0],
    value: string
  ): void => {
    this.setState((prevState) => {
      const updatedTimeSlots = [...prevState.timeSlot];
      updatedTimeSlots[index][key] = value;
      return { timeSlot: updatedTimeSlots };
    });
  };

  toggleSelectAllAirports() {
    const { userSelectedAirports, allAirports } = this.state;

    if (userSelectedAirports.length === allAirports.length) {
      this.setState({ userSelectedAirports: [] });
    } else {
      this.setState({ userSelectedAirports: allAirports });
    }
  }

  toggleSelectAllAirportsEdit() {
    const { editselectedAirports, allAirports } = this.state;

    if (editselectedAirports.length === allAirports.length) {
      this.setState({ editselectedAirports: [] });
    } else {
      this.setState({ editselectedAirports: allAirports });
    }
  }

  toggleSelectAllTestsEdit() {
    const { editSelectedTests, allTests } = this.state;

    if (editSelectedTests.length === allTests.length) {
      this.setState({ editSelectedTests: [] });
    } else {
      this.setState({ editSelectedTests: allTests });
    }
  }

  handleCloseDialog(){
    this.setState({openDialog: false})
  }

  handleOpenDialog(){
    this.setState({openDialog: true})
  }

  toggleSelectAllTests() {
    const { userSelectedTests, allTests } = this.state;

    if (userSelectedTests.length === allTests.length) {
      this.setState({ userSelectedTests: [] });
    } else {
      this.setState({ userSelectedTests: allTests });
    }
  }

  toggleSelectAllMembers() {
    const { userSelectedMembers, allMembers } = this.state;

    if (userSelectedMembers.length === allMembers.length) {
      this.setState({ userSelectedMembers: [] });
    } else {
      this.setState({ userSelectedMembers: allMembers });
    }
  }


  handleAirportCheckbox(serviceSubcategoryId: number) {
    const isChecked = this.state.userSelectedAirports.some(
      (airport) => airport.service_subcategory_id === serviceSubcategoryId
    );

    return isChecked;
  }

  handleAirportCheckboxEdit(serviceSubcategoryId: number) {
    const isChecked = this.state.editselectedAirports.some(
      (airport) => airport.service_subcategory_id === serviceSubcategoryId
    );

    return isChecked;
  }

  handleSelectAirport(item: any) {
    const { userSelectedAirports } = this.state;

    const index = userSelectedAirports.findIndex(
      (airport) =>
        airport.service_subcategory_id === item.service_subcategory_id
    );

    let updatedUserSelectedAirports;
    if (index === -1) {
      updatedUserSelectedAirports = [...userSelectedAirports, item];
    } else {
      updatedUserSelectedAirports = userSelectedAirports.filter(
        (airport) =>
          airport.service_subcategory_id !== item.service_subcategory_id
      );
    }

    this.setState({ userSelectedAirports: updatedUserSelectedAirports });
  }

  handleSelectAirportEdit(item: any) {
    const { editselectedAirports } = this.state;

    const index = editselectedAirports.findIndex(
      (airport) =>
        airport.service_subcategory_id === item.service_subcategory_id
    );

    let updatedUserSelectedAirports;
    if (index === -1) {
      updatedUserSelectedAirports = [...editselectedAirports, item];
    } else {
      updatedUserSelectedAirports = editselectedAirports.filter(
        (airport) =>
          airport.service_subcategory_id !== item.service_subcategory_id
      );
    }

    this.setState({ editselectedAirports: updatedUserSelectedAirports });
  }

  handleDeleteAvailability(){
    this.setState({deleteAvailabilityDialogOpen: false, isViewAvailabiliDialogOpen: false, isEditAvailabilityOpen: false}, ()=>{
      this.deleteAvailability()
    })
  }

  timePickerInputRefs: { [key: string]: HTMLInputElement | null } = {};
  formatSelectedTest = (values: any[]) => {
    if (values.length === 1) {
      return values[0].designation ?? values[0]?.service_subcategory_name; 
    }
    return `${values[0].designation ?? values[0]?.service_subcategory_name}, +${values.length - 1} more`;
  };

  formatSelectedAirports = (values: any[]) => {
    if (values.length === 1) {
      return values[0]?.service_subcategory_name; 
    }
    if (values.length === 2) {
      return `${values[0]?.service_subcategory_name}, ${values[1].service_subcategory_name}`; 
    }
    if (values.length === 3) {
      return `${values[0]?.service_subcategory_name}, ${values[1].service_subcategory_name}, ${values[2].service_subcategory_name}`; 
    }
    return `${values[0]?.service_subcategory_name}, ${values[1].service_subcategory_name}, ${values[2].service_subcategory_name} +${values.length - 3} more`;
  };

  formatSelectedMembers = (values: any[]) => {
    if (values.length === 1) {
      return values[0].name; 
    }
    if (values.length === 2) {
      return `${values[0]?.name}, ${values[1].name}`; 
    }
    return `${values[0].name}, ${values[1].name} +${values.length - 2} more`;
  };

  handleSelectTests(item: any) {
    const { userSelectedTests } = this.state;

    const index = userSelectedTests.findIndex((tests) => tests.id === item.id);

    let updatedUserSelectedTests;
    if (index === -1) {
      updatedUserSelectedTests = [...userSelectedTests, item];
    } else {
      updatedUserSelectedTests = userSelectedTests.filter(
        (tests) => tests.id !== item.id
      );
    }

    this.setState({ userSelectedTests: updatedUserSelectedTests });
  }

  handleSelectMembers(item: any) {
    const { userSelectedMembers } = this.state;

    const index = userSelectedMembers.findIndex((member) => member.id === item.id);
    let updatedUserSelectedMembers;
    if (index === -1) {
      updatedUserSelectedMembers = [...userSelectedMembers, item];
    } else {
      updatedUserSelectedMembers = userSelectedMembers.filter(
        (member) => member.id !== item.id
      );
    }
    this.setState({ userSelectedMembers: updatedUserSelectedMembers });
  }

  convertToTimeString = (timestamp: string) => {
    const isTimeFormat = /^\d{2}:\d{2}$/.test(timestamp);
  
    if (isTimeFormat) {
      return timestamp;
    }
    return moment(timestamp).format("HH:mm");
  };

  shouldEnableCreateAvailablityBtn() {
    const { userSelectedAirports, userSelectedTests, shouldNotifyAlluser, userSelectedMembers } = this.state;
  
    return (
      userSelectedAirports.length >= 1 &&
      userSelectedTests.length >= 1 &&
      (shouldNotifyAlluser || userSelectedMembers.length >= 1)
    );
  }

  shouldEnableSaveEditedAvailablityBtn() {
    const { editSelectedTests, editselectedAirports, shouldNotifyAlluser, userSelectedMembers } = this.state;
  
    return (
      editselectedAirports.length >= 1 &&
      editSelectedTests.length >= 1 &&
      (shouldNotifyAlluser || userSelectedMembers.length >= 1)
    );
  }

  getColor = () => {
    if (this.state.userSelectedAirports.length > 0 && !this.state.isSelectAirportOpen) {
      return "#0F172A";
    }
    return this.state.isSelectAirportOpen ? "#0074BF" : "#475569";
  };

  getColorEdit = () => {
    if (this.state.editselectedAirports.length > 0 && !this.state.isSelectAirportOpen) {
      return "#0F172A";
    }
    return this.state.isSelectAirportOpen ? "#0074BF" : "#475569";
  };

  getSelectedTestColor = () => {
    if (this.state.userSelectedTests.length > 0 && !this.state.isSelectTestTypeOpen) {
      return "#0F172A";
    }
    return this.state.isSelectTestTypeOpen ? "#0074BF" : "#475569"
  };

  getSelectedTestColorEdit = () => {
    if (this.state.editSelectedTests.length > 0 && !this.state.isSelectTestTypeOpen) {
      return "#0F172A";
    }
    return this.state.isSelectTestTypeOpen ? "#0074BF" : "#475569"
  };

  getSelectedDpeTestColorEdit = () => {
    if (this.state.depSelectedTests  && !this.state.isDpeTestTypeOpen) {
      return "#475569";
    }
    return this.state.isDpeTestTypeOpen ? "#0074BF" : "#475569"
  };

  getSelectedDpeAirportolorEdit = () => {
    if (this.state.depSelectedAirport  && !this.state.isDpeAirportSelectOpen) {
      return "#475569";
    }
    return this.state.isDpeAirportSelectOpen ? "#0074BF" : "#475569"
  };

  handleCancelSetDetails(){
    this.setState({ isSetDetailsOpen: false, open: true, userSelectedAirports: [], userSelectedTests: [], shouldNotifyAlluser: true});
  }

  getStartTime(){
    return this.state.timeSlot.map((slot) => this.convertToTimeString(slot.starttime))
  }

  getEndTime(){
    return this.state.timeSlot.map((slot) => this.convertToTimeString(slot.endtime))
  }

  getAvailablityDates() {
    return [
      moment(this.state.availabilityDate).format("YYYY-MM-DD")
    ];
  }

  getAirportForApiBody(){
    return this.state.userSelectedAirports.map((item) => ({
      service_subcategory_id: item.service_subcategory_id,
    }));
  }

  getTestsForApiBody(){
    return this.state.userSelectedTests.map((item) => ({
      service_subcategory_id: item.id,
    }));
  }

  getEditedAirportForApiBody(){
    return this.state.editselectedAirports.map((item) => ({
      service_subcategory_id: item.service_subcategory_id,
    }));
  }

  getEditedTestsForApiBody(){
    return this.state.editSelectedTests.map((item) => ({
      service_subcategory_id: item.id,
    }));
  }

  async handleSaveAvailability(){
    await this.saveAvailablity()
    this.setState({ isSetDetailsOpen: false, open: false });
  }

  getAirportsSucessCallBack = (response: any) => {
    this.setState({
        ...this.state,
        allAirports: response
    });
  }

  formatSelectedTestForView = (values: any[]) => {
    if (values.length === 1) {
      return values[0].service_subcategory_name; 
    }
    return `${values[0]?.service_subcategory_name}, +${values.length - 1} more`;
  };


  getAvailabilitySucessCallBack = (response: any) => {
    const availability = response?.data?.availability;
    const airports = response?.data?.airports
    const designations = response?.data?.designations
    const updatedDesignations = designations.map((des: any) => ({
      ...des,
      id: des.service_subcategory_id,
    }))
    this.setState({
      editselectedAirports:airports,
      editSelectedTests: updatedDesignations,
      selectedTimeSlotForEdit:{ 
        starttime: this.state.selectedAvailability.availability?.start_time ?? availability.timeslots[0].from,
        endtime: this.state.selectedAvailability.availability?.end_time ?? availability.timeslots[0].to,
      },
      availabilityDate: moment(availability.availability_date, "DD/MM/YYYY").format("dddd, MMMM D, YYYY"),
      selectedTimeZone: availability.time_zone,
      selectedAvailability:{
        airports,
        designations,
        availability:{
          id: availability.id,
          status: availability.status,
          availability_date: this.formatDate(availability.availability_date),
          start_time: this.state.selectedAvailability.availability?.start_time ?? availability.timeslots[0].from,
          end_time: this.state.selectedAvailability.availability?.end_time ?? availability.timeslots[0].to,
          time_zone: availability.time_zone,
          availabilityAirport: airports[0]?.service_subcategory_name,
          availabilityTests: designations[0]?.service_subcategory_name,
          available_slots_count: availability.timeslots.length
        }
      }
    })
  }

  handleTestDrodownColor() {
    const { isTestPopupOpen } = this.state
    if (isTestPopupOpen) {
      return {
        color: "#0074BF",
        marginBottom:"8px"
      }
    }
    return {
      color: "#475569",
      marginBottom:"8px"
    }
  }

  handleAircraftDrodownColor() {
    const { isAircraftPopupOpen } = this.state
    if (isAircraftPopupOpen) {
      return {
        color: "#0074BF",
        marginBottom:"8px"
      }
    }
    return {
      color: "#475569",
      marginBottom:"8px"
    }
  }

  handleTestDrodownIconColor() {
    const { isTestPopupOpen } = this.state
    if (isTestPopupOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleDpeTestDrodownIconColor() {
    const { isDpeTestTypeOpen } = this.state
    if (isDpeTestTypeOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleDpeAirportDrodownIconColor() {
    const { isDpeAirportSelectOpen } = this.state
    if (isDpeAirportSelectOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleAircraftDrodownIconColor() {
    const { isAircraftPopupOpen } = this.state
    if (isAircraftPopupOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleToggleTestPopup() {
    const { isTestPopupOpen } = this.state
    this.setState({ isTestPopupOpen: !isTestPopupOpen, isAircraftPopupOpen: false, isAirportPopupOpen: false })
  }

  handleToggleGradePopup() {
    const { isGradePopupOpen } = this.state
    this.setState({ isGradePopupOpen: !isGradePopupOpen })
  }

  handleToggleCategoryPopup() {
    const { isCategoryPopupOpen } = this.state
    this.setState({ isCategoryPopupOpen: !isCategoryPopupOpen})
  }

  handleToggleClassPopup() {
    const { isClassPopupOpen } = this.state
    this.setState({ isClassPopupOpen: !isClassPopupOpen})
  }

  handleGradeHeaderBg(){
    const {isGradePopupOpen} = this.state
    if(isGradePopupOpen){
      return {
        backgroundColor: "#F8FAFC"
      }
    }
    return {
      backgroundColor: "#fff"
    }
  }

  handleCategoryHeaderBg(){
    const {isCategoryPopupOpen} = this.state
    if(isCategoryPopupOpen){
      return {
        backgroundColor: "#F8FAFC"
      }
    }
    return {
      backgroundColor: "#fff"
    }
  }

  handleClassHeaderBg(){
    const {isClassPopupOpen} = this.state
    if(isClassPopupOpen){
      return {
        backgroundColor: "#F8FAFC"
      }
    }
    return {
      backgroundColor: "#fff"
    }
  }

  handleGradeIcon(){
    const {isGradePopupOpen} = this.state
    if(isGradePopupOpen){
      return remove
    }
    return add
  }

  handleCategoryIcon(){
    const {isCategoryPopupOpen} = this.state
    if(isCategoryPopupOpen){
      return remove
    }
    return add
  }

  handleClassIcon(){
    const {isClassPopupOpen} = this.state
    if(isClassPopupOpen){
      return remove
    }
    return add
  }

  toggleChipBackgroundColor(isSelected: boolean){
    if(isSelected){
      return "#0074BF"
    }
    return "#f1f5f9"
  }

  toggleChipColor(isSelected: boolean){
    if(isSelected){
      return "#ffffff"
    }
    return "#001c55"
  }

  toggleSelectAllGrades() {
    const { allGradeData } = this.state;
  
    const allSelected = this.areAllGradesSelected();
  
    const updatedGradeData = allGradeData.map((item: any) => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filteredGradeData: updatedGradeData,
      allGradeData: updatedGradeData,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllGradesSelected() {
    const { filteredGradeData } = this.state;
    return filteredGradeData.every((item: { selected: any; }) => item.selected);
  }

  handleAllGradesCheckTextColor() {
    const allSelected = this.areAllGradesSelected();
    return allSelected ? "#fff": "#001c55" ;
  }

  handleAllGradesCheckChipColor() {
    const allSelected = this.areAllGradesSelected();
    return allSelected ? "#0074BF": "#f1f5f9" ;
  }

  toggleGradeSelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allGradeData));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      const areFilteredListsEqual = isEqual(prevState.allGradeData, finalUpdatedList);
      return {
        allGradeData: finalUpdatedList,
        filteredGradeData: finalUpdatedList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

   toggleSelectAllCategories() {
    const { allCategoryData } = this.state;
  
    const allSelected = this.areAllCategoriesSelected(); 
  
    const updatedCategoryData = allCategoryData.map((item: any) => ({ ...item, selected: !allSelected }));
  
    this.setState({ 
      filteredCategoryData: updatedCategoryData,
      allCategoryData: updatedCategoryData ,
      isResetEnabled: allSelected ? true : false
    });
  }

  areAllCategoriesSelected() {
    const { filteredCategoryData } = this.state;
    return filteredCategoryData.every((item: { selected: any; }) => item.selected);
  }

  handleAllCategoriesCheckTextColor() {
    const allSelected = this.areAllCategoriesSelected();
    return allSelected ? "#fff": "#001c55" ;
  }

  handleAllCategoriesCheckChipColor() {
    const allSelected = this.areAllCategoriesSelected();
    return allSelected ? "#0074BF": "#f1f5f9" ;
  }

   toggleCategorySelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = prevState.allCategoryData;
      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      const areFilteredListsEqual = isEqual(prevState.allCategoryData, finalUpdatedList);
      return {
        allCategoryData: finalUpdatedList,
        filteredCategoryData: finalUpdatedList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

   toggleSelectAllClass() {
    const { allClassData } = this.state;
  
    const allSelected = this.areAllClassesSelected();
  
    const updatedClassData = allClassData.map((item: any) => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filterClassData: updatedClassData,
      allClassData: updatedClassData,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllClassesSelected() {
    const { filterClassData } = this.state;
    return filterClassData.every((item: { selected: any; }) => item.selected);
  }

  handleAllClassCheckTextColor() {
    const allSelected = this.areAllClassesSelected();
    return allSelected ? "#fff": "#001c55" ;
  }

  handleAllClassCheckChipColor() {
    const allSelected = this.areAllClassesSelected();
    return allSelected ? "#0074BF": "#f1f5f9" ;
  }

   toggleClassSelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allClassData));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });
      const areFilteredListsEqual = isEqual(prevState.allClassData, finalUpdatedList);
      return {
        allClassData: finalUpdatedList,
        filterClassData: finalUpdatedList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

  handleToggleAircraftPopup() {
    const { isAircraftPopupOpen } = this.state
    this.setState({ isAircraftPopupOpen: !isAircraftPopupOpen, isAirportPopupOpen: false, isTestPopupOpen: false })
  }

  handleSearchAircraft = (text: string) => {
    this.setState(prevState => ({
      searchQuery: text,
      filteredAircrafts: prevState.allAircrafts
        .map(item => ({ ...item }))
        .filter(item => item.name.toLowerCase().includes(text.toLowerCase()))
    }));
  };

  toggleSelectAllAircraft() {
    const { allAircrafts } = this.state;
  
    const allSelected = this.areAllAircraftsSelected();
  
    const updatedAircrafts = allAircrafts.map(item => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filteredAircrafts: updatedAircrafts,
      allAircrafts: updatedAircrafts,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllAircraftsSelected() {
    const { filteredAircrafts } = this.state;
    return filteredAircrafts.every(item => item.selected);
  }

  handleAllAircraftCheckIcon() {
    const allSelected = this.areAllAircraftsSelected();
    return allSelected ? checkedBox : unCheckedBox;
  }


  toggleAircraftSelection = (id: string) => {
    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allAircrafts));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });

      const updatedFilteredList = finalUpdatedList.filter((item: any) =>
        item.name.toLowerCase().includes(prevState.searchQuery.toLowerCase())
      );
      const areFilteredListsEqual = isEqual(prevState.filteredAircrafts, updatedFilteredList);
      return {
        allAircrafts: finalUpdatedList,
        filteredAircrafts: updatedFilteredList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
  };

  resetFilter = () => {
    if(this.state.isResetEnabled === false) return
    this.setState(prevState => ({
      searchQuery: "",
      filteredAircrafts: [...prevState.initialFilters.aircrafts], 
      searchQueryAirport: "",
      filteredAirports: [...prevState.initialFilters.airports], 
      filteredGradeData: [...prevState.initialFilters.grades], 
      filterClassData: [...prevState.initialFilters.classes], 
      filteredCategoryData: [...prevState.initialFilters.categories], 
      isResetEnabled: false,
    }));
  };

  handleToggleAirportPopup() {
    const { isAirportPopupOpen } = this.state
    this.setState({ isAirportPopupOpen: !isAirportPopupOpen, isAircraftPopupOpen: false, isTestPopupOpen: false })
  }

  handleAirportDrodownIconColor() {
    const { isAirportPopupOpen } = this.state
    if (isAirportPopupOpen) {
      return "#0074BF"
    }
    return "#94A3B8"
  }

  handleAirportDrodownColor() {
    const { isAirportPopupOpen } = this.state
    if (isAirportPopupOpen) {
      return {
        color: "#0074BF", 
        marginBottom:"8px"
      }
    }
    return {
      color: "#475569",
      marginBottom:"8px"
    }
  }

  handleSearchAirports = (text: string) => {
    this.setState(prevState => ({
      searchQueryAirport: text,
      filteredAirports: prevState.allAirportsForFilter
        .map(item => ({ ...item }))
        .filter(item => item.name.toLowerCase().includes(text.toLowerCase()))
    }));
  };

  toggleSelectAllAirport() {
    const { allAirportsForFilter } = this.state;
  
    const allSelected = this.areAllAirportsSelected();
  
    const updatedAirports = allAirportsForFilter.map(item => ({ ...item, selected: !allSelected }));
  
    this.setState({
      filteredAirports: updatedAirports,
      allAirportsForFilter: updatedAirports,
      isResetEnabled: allSelected ? true : false
    });
  }
  
  areAllAirportsSelected() {
    const { filteredAirports } = this.state;
    return filteredAirports.every(item => item.selected);
  }

  handleAllAirportCheckIcon() {
    const allSelected = this.areAllAirportsSelected();
    return allSelected ? checkedBox : unCheckedBox;
  }

  toggleAirportSelection = (id: string) => {

    this.setState(prevState => {
      const updatedList = JSON.parse(JSON.stringify(prevState.allAirportsForFilter));

      const finalUpdatedList = updatedList.map((item: any) => {
        if (item.id === id) {
          return { ...item, selected: !item.selected };
        }
        return item;
      });

      const updatedFilteredList = finalUpdatedList.filter((item: any) =>
        item.name.toLowerCase().includes(prevState.searchQuery.toLowerCase())
      );
      const areFilteredListsEqual = isEqual(prevState.filteredAirports, updatedFilteredList);
      return {
        allAirportsForFilter: finalUpdatedList,
        filteredAirports: updatedFilteredList,
        isResetEnabled: !areFilteredListsEqual
      };
    });
   };

  getSaveAvailabilitySucessCallBack = (response: any) => {
    if(response){
      this.setState({
        ...this.state,
        isAlert: true,
        alertType: response.message === "Availabilities created successfully." ? "success" : "error",
        alertMsg: response.message === "Availabilities created successfully." ? "Availabilities created successfully!" : response.error.message
      }, () => {
        const currentDate = new Date();
        this.calculateMonthDates(currentDate); 
      });
      this.handleReset()
      window.location.reload();
    }
  }

  updateAvailabilitySuccessCallBack = (response: any) => {
    if(response){
      const airports = response?.data?.airports
      const designations = response?.data?.designations
      const availability = response?.data?.availability;
      const updatedDesignations = designations.map((des: any) => ({...des,id: des.service_subcategory_id,}))
      this.setState({
        isEditAvailabilityOpen:false,
        alertType: response.message === "Availability updated successfully." ? "success" : "error",
        alertMsg: response.message === "Availability updated successfully."
          ? "Availability updated successfully!"
          : response.error?.message || response.error,
        isViewAvailabiliDialogOpen: true,
        isAlert: true,
        selectedTimeSlotForEdit:{ 
          starttime: this.state.selectedAvailability.availability?.start_time ?? availability.timeslots[0].from,
          endtime: this.state.selectedAvailability.availability?.end_time ?? availability.timeslots[0].to,
        },
        editselectedAirports:airports,
        editSelectedTests: updatedDesignations,
        availabilityDate: moment(availability.availability_date, "DD/MM/YYYY").format("dddd, MMMM D, YYYY"),
        selectedTimeZone: availability.time_zone,
        selectedAvailability:{
          designations,airports,
          availability:{
            id: availability.id,
            availabilityTests: designations[0]?.service_subcategory_name,
            available_slots_count: availability.timeslots.length,
            time_zone: availability.time_zone,
            availabilityAirport: airports[0]?.service_subcategory_name,
            status: availability.status,
            availability_date: this.formatDate(availability.availability_date),
            start_time: availability.timeslots[0].from,
            end_time: availability.timeslots[0].to,
          }
        }
      })
    }
  }

  deleteAvailabilitySuccessCallBack = (response: any) => {
    if(response){
      this.setState({
        ...this.state,
        deleteAvailabilityDialogOpen:false,
        isAlert: true,
        alertType: response.message === "Availability deleted successfully" ? "success" : "error",
        alertMsg: response.message === "Availability deleted successfully"
        ? "Availability deleted successfully!"
        : response.error?.message || response.error
      });
      window.location.reload();
    }
  }

  formatTimeSlot(timeslot: { from: string; to: string }): string {
    const fromParts = timeslot.from.split(" ");
    const toParts = timeslot.to.split(" ");

    const fromTime = fromParts[0];
    const toTime = toParts[0];
    const period = toParts[1];

    return `${fromTime} - ${toTime} ${period}`;
  }

  handleReset(){
    this.setState({
      ...this.state,
      timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
      selectedTimeZone: "Eastern Standard Time (EST)",
      userSelectedAirports: [],
      userSelectedTests: [],
      availabilityDate: new Date() as any,
      isAlert: true,
      isCalenderOpen: false,
      isNotifyContainerOpen: false,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
      isSetDetailsOpen: false,
    });
  }

  handleCancel(){
      this.setState({
        ...this.state,
        timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
        selectedTimeZone: "Eastern Standard Time (EST)",
        userSelectedAirports: [],
        userSelectedTests: [],
        availabilityDate: new Date() as any,
        isAlert: false,
        isCalenderOpen: false,
        isNotifyContainerOpen: false,
        isSelectAirportOpen: false,
        isSelectTestTypeOpen: false,
        isSetDetailsOpen: false,
        shouldNotifyAlluser: true,
        open: false,
        isEditAvailabilityOpen:false,
        alertMsg:"",
      });
  }

  handleCancelEdit(){
    this.setState({
      ...this.state,
      timeSlot: [{ starttime: "10:00", endtime: "11:00" }],
      selectedTimeZone: "Eastern Standard Time (EST)",
      userSelectedAirports: [],
      userSelectedTests: [],
      availabilityDate: new Date() as any,
      isAlert: false,
      isCalenderOpen: false,
      isNotifyContainerOpen: false,
      isSelectAirportOpen: false,
      isSelectTestTypeOpen: false,
      isSetDetailsOpen: false,
      shouldNotifyAlluser: true,
      open: false,
      isViewAvailabiliDialogOpen:true,
      isEditAvailabilityOpen:false,
      alertMsg:"",
    });
}

  getTestsSucessCallBack = (response: any) => {
    this.setState({
        ...this.state,
        allTests: response
    });
  }

  handleSelectTimezone(timezone:string, index:number){
      this.setState({
        selectedTimeZone: timezone,
        showtimeZoneOverlay: false,
      });
  }

  handleAllCheckIconforTests() {
    if (this.state.userSelectedTests.length === this.state.allTests.length) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleAllCheckIconforMembers() {
    if (this.state.userSelectedMembers.length === 10 ) {
      this.setState({...this.state, userSelectedMembers: this.state.allMembers.slice(0,10)})
      return checkedBox;
    }else if (this.state.userSelectedMembers.length === this.state.allMembers.length ) {
      return checkedBox;
    }
    return unCheckedBox;
  }

  handleMemberCheckbox(id: number) {
    const isChecked = this.state.userSelectedMembers.some(
      (member) => member.id === id
    );

    return isChecked;
  }

  handleToggleSwitch(checked: boolean) {
    this.setState({ shouldNotifyAlluser: checked, isSelectAirportOpen:false, isSelectTestTypeOpen:false })
  }

  handleTestCheckbox(id: number) {
    const isChecked = this.state.userSelectedTests.some(
      (tests) => tests.id === id
    );

    return isChecked;
  }

  handleUpdateTimeSlotForEdit = (
    key: keyof S["selectedTimeSlotForEdit"],
    value: string
  ): void => {
    this.setState((prevState) => ({
      selectedTimeSlotForEdit: {
        ...prevState.selectedTimeSlotForEdit,
        [key]: value,
      },
    }));
  };

  getAvailabilityStarttime() {
    const startTime = this.state.selectedTimeSlotForEdit?.starttime
      ? moment(this.state.selectedTimeSlotForEdit.starttime, ["hh:mm A", "HH:mm"]).format("HH:mm")
      : this.state.selectedAvailability.availability?.start_time;
  
    return [startTime];
  }

  getAvailabilityEndtime() {
    const endTime = this.state.selectedTimeSlotForEdit?.endtime
      ? moment(this.state.selectedTimeSlotForEdit.endtime, ["hh:mm A", "HH:mm"]).format("HH:mm") 
      : this.state.selectedAvailability.availability?.end_time;
  
    return [endTime];
  }

  handleTestCheckboxforedit(id: number) {
    const isChecked = this.state.editSelectedTests.some(
      (tests) => tests.id === id
    );

    return isChecked;
  }

  handleSelectTestsforedit(item: any) {
    const { editSelectedTests } = this.state;
    const index = editSelectedTests.findIndex((tests) => tests.id === item.id);

    let updatedUserSelectedTests;
    if (index === -1) {
      updatedUserSelectedTests = [...editSelectedTests, item];
    } else {
      updatedUserSelectedTests = editSelectedTests.filter(
        (tests) => tests.id !== item.id
      );
    }

    this.setState({ editSelectedTests: updatedUserSelectedTests });
  }

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any) {
    const token = this.state.token || "";
    if (profileData && profileData.id && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    } else if (this.state.serviceProviderId && selectedDateStr) {
      this.getServiceProviderDetails({
        setApiCallId: "serviceProviderScheduleApiId",
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token,
      });
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };

    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null,
    });
    return true;
  }

  getAirportsRequest = () => {
    const token = localStorage.getItem('accessToken')
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAirportsApiCallId = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_categories/dpe_airport_settings"
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async getAllSelectedTestsByDPE() {
    const token = localStorage.getItem('accessToken')
    const header = {
      "Content-Type": "application/json",
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSelectedTestsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_categories/designation_instructions/designation_instructions_for_dpe"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async saveAvailablity() {
    if (this.shouldEnableCreateAvailablityBtn()) {
      const token = localStorage.getItem('accessToken')
      const header = {
        "Content-Type": "application/json",
        token
      };

      const data = {
        availabilities: [
          {
            start_time: this.getStartTime(),
            end_time: this.getEndTime(),
            availability_date: this.getAvailablityDates(),
            time_zone: this.state.selectedTimeZone,
            airports: this.getAirportForApiBody(),
            designations: this.getTestsForApiBody(),
          },
        ],
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.saveAvailablityApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_appointment_management/availabilities/create_multiple"
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  apiCall = async (data: any) => {
    const { setApiCallId, header, endPoint, method, body } = data;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === "serviceProviderDetailApiId") {
      this.serviceProviderDetailApiId = requestMessage.messageId;
    } else if (setApiCallId === "serviceProviderScheduleApiId") {
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsubscribeMessages = () => {
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  };

  fetchSingleAvailibility = async (id: number) => {
    this.setState({isLoading: true})
    this.fetchSingleAvailibilityForDpeApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: `bx_block_appointment_management/availabilities/${id}`
    })
  };

  async fetchAvailabilityDetails(id:number){
    const token= localStorage.getItem('accessToken')

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAvailabilityDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  availabilityGet = (startDate: Date, endDate: Date) => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const formattedStartDate = moment(startDate).format("DD/MM/YYYY"); // Format the start date
    const formattedEndDate = moment(endDate).format("DD/MM/YYYY"); 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabilityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities?start_date=${formattedStartDate}&end_date=${formattedEndDate}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  filterGet = () => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
   
    const getSelectedIdsfilter = (data: any[]) =>
      data
        ?.filter((item: any) => item.selected === true) // Keep only selected = true
        .map((item: any) => item.id) // Extract IDs
        .join(","); // Convert to comma-separated string
  
    // Get selected IDs from different states
    const gradeIds = getSelectedIdsfilter(this.state.filteredGradeData);
    const categoryIds = getSelectedIdsfilter(this.state.filteredCategoryData);
    const classIds = getSelectedIdsfilter(this.state.filterClassData);
    const aircraftIds = getSelectedIdsfilter(this.state.filteredAircrafts);
    const airportIds = getSelectedIdsfilter(this.state.filteredAirports);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterGetApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/get_dpe_users?grade_of_certificate=[${gradeIds}]&category=[${categoryIds}]&class=[${classIds}]&aircraft_type=[${aircraftIds}]&airport=[${airportIds}]&dpe_users_with_availability=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  filterGet4 = () => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
   
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterGetApiCallId4 = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/get_filtered_availabilities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  availabilityApplicant = (startDate: Date, endDate: Date) => {
    let token= localStorage.getItem('accessToken')
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getSelectedIds = (data: any[]) =>
      data
        ?.filter((item: any) => item.selected === true) 
        .map((item: any) => item.id) 
        .join(","); 
  
    
    const gradeIds = getSelectedIds(this.state.filteredGradeData);
    const categoryIds = getSelectedIds(this.state.filteredCategoryData);
    const classIds = getSelectedIds(this.state.filterClassData);
    const aircraftIds = getSelectedIds(this.state.filteredAircrafts);
    const airportIds = getSelectedIds(this.state.filteredAirports);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.availabilityApplicantApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
`bx_block_appointment_management/availabilities/get_filtered_availabilities?grade_of_certificate=[${gradeIds}]&category=[${categoryIds}]&class=[${classIds}]&aircraft_type=[${aircraftIds}]&airport=[${airportIds}]&start_date=${this.state.startDate}&end_date=${this.state.endDate}`    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

   

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  componentDidUpdate(prevProps: Props, prevState: any) {
    if (prevState.availability !== this.state.availability) {
      this.transformEvents();
      const currentDate = new Date(); 
      this.calculateMonthDates(currentDate); 
    }
    if (prevState.selectedAvailability.availability !== this.state.selectedAvailability.availability) {
      this.setState({selectedAvailability: this.state.selectedAvailability})
    }
  }

 
  
  transformEvents = () => {
    if (!this.state.availability?.data?.availability) return;
    const transformedEvents = this.state.availability.data.availability.flatMap((avail: any) =>
      avail.data.attributes.time_slots.map((slot: any) => ({
        id: avail.data.id,
        title: `${slot.from}`, 
        start: moment(
          `${avail.data.attributes.availability_date} ${slot.from}`,
          "DD/MM/YYYY hh:mm A"
        ).toDate(),
        end: moment(
          `${avail.data.attributes.availability_date} ${slot.to}`,
          "DD/MM/YYYY hh:mm A"
        ).toDate(),
        allDay: false,
        booked_status: slot.booked_status, // Store the booked status
        user_name: avail.data.attributes.user_name,
        airport: avail.data.attributes.airports?.[0]?.airport || "",      }))
    );
    
    this.setState({ events: transformedEvents });
    console.log(this.state.events.user_name,"eventsevents")
  };

  getAirportContainerPlaceholderEdit() {
    const { editselectedAirports } = this.state;
    const length = editselectedAirports.length;

    if (length === 0) {
      return "Select airport(s)";
    }

    const airportNames = editselectedAirports
      .slice(0, 3)
      .map((airport) => airport.service_subcategory_name);

    if (length > 3) {
      return `${airportNames.join(", ")}, +${length - 3} more`;
    }

    return airportNames.join(", ");
  }

  getTestContainerPlaceholderEdit() {
    const { editSelectedTests } = this.state;
    const length = editSelectedTests.length;
    if (length === 0) {
      return "Select test type(s)";
    }

    const firstTestName = editSelectedTests[0]?.service_subcategory_name;

    if (firstTestName?.length === 0 || firstTestName === undefined) {
      return "Select test type(s)"
    }

    if (length > 1) {
      return `${firstTestName}, +${length - 1} more`;
    }

    return firstTestName;
  }

  getDpeAirportSelectLabelEdit() {
    const { isDpeAirportSelectOpen, depSelectedAirport } = this.state;
    if (!isDpeAirportSelectOpen && depSelectedAirport.id !== -1) {
      return "AIRPORT SELECTED";
    }
    if(isDpeAirportSelectOpen) return
    return "AIRPORTS";
  }

  getDpeTestsSelectLabelEdit() {
    const { isDpeTestTypeOpen, depSelectedTests } = this.state;
    if (!isDpeTestTypeOpen && depSelectedTests.id !== -1) {
      return "TEST SELECTED";
    }
    if(isDpeTestTypeOpen) return
    return "TESTS";
  }

  handleTermsCheck(){
    this.setState({isTermsChecked: !this.state.isTermsChecked, isDpeTestTypeOpen: false, isDpeAirportSelectOpen: false})
  }

  getDpeAirportContainerPlaceholderEdit() {
    const { depSelectedAirport, isDpeAirportSelectOpen } = this.state;
  
    if (!depSelectedAirport || isDpeAirportSelectOpen || !depSelectedAirport.service_subcategory_name) {
      return "SELECT AIRPORT";
    }
  
    return depSelectedAirport.service_subcategory_name;
  }
  

  handleCancelBookAppointment() {
    localStorage.removeItem("applicantAvailabilityId")
    this.setState({isViewApplicantsAvailabilityDialogOpen: false, 
                  isDpeAirportSelectOpen: false, 
                  isDpeTestTypeOpen: false,
                  isTermsChecked: false,
                  depSelectedAirport: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" },
                  depSelectedTests: { id: -1, service_subcategory_id: -1, service_subcategory_name: "" }})
  }

  getDpeTestContainerPlaceholderEdit() {
    const { depSelectedTests, isDpeTestTypeOpen } = this.state;
  
    if (!depSelectedTests || isDpeTestTypeOpen || !depSelectedTests.service_subcategory_name) {
      return "SELECT TEST";
    }
  
    return depSelectedTests.service_subcategory_name;
  }
  

  getDesignationPlaceholderWhenClosed() {
    const selectedDesignation = this.state.depSelectedTests
    if (selectedDesignation.id !== -1) {
      return selectedDesignation.service_subcategory_name
    } 

  return this.formatDesignation(this.state.dpeAllTest)
  }

  formatDesignation(designations: IDesignations[]) {
    const uniqueTests = [...new Set(designations.map(a => a.service_subcategory_name))];

    if (uniqueTests.length <= 1) {
      return uniqueTests.join(", ");
    } else {
      return uniqueTests.slice(0, 1).join(", ") + ", +" + (designations.length - 1) + " more";
    }
  }

  getAirportPlaceholderWhenClosed() {
    const selectedAirport = this.state.depSelectedAirport
    if (selectedAirport.id !== -1) {
      return selectedAirport.service_subcategory_name
    }

    return this.formatAirportList(this.state.dpeAllAirport)
  }

  formatAirportList(airports: IAirport[]) {
    const uniqueAirports = [...new Set(airports.map(a => a.service_subcategory_name))];

    if (uniqueAirports.length <= 3) {
      return uniqueAirports.join(", ");
    } else {
      return uniqueAirports.slice(0, 3).join(", ") + ", +" + (airports.length - 3) + " more";
    }
  }

  handleDpeTestCheckboxforedit(id: number) {
    const isChecked = this.state.depSelectedTests.service_subcategory_id === id;

    return isChecked;
  }

  handleDpeTestBackgroundColor(id: number) {
    const isChecked = this.state.depSelectedTests.service_subcategory_id === id;

    return isChecked ? "#E0F2F8" : "#fff";
  }

  handleDpeAirportBackgroundColor(id: number) {
    const isChecked = this.state.depSelectedAirport.service_subcategory_id === id;

    return isChecked ? "#E0F2F8" : "#fff";
  }


  handleDpeAirportCheckboxforedit(id: number) {
    const isChecked = this.state.depSelectedAirport.service_subcategory_id === id;

    return isChecked;
  }

  
  shouldProceedToBookingAppointment() {
    const { depSelectedAirport, depSelectedTests, isTermsChecked } = this.state
    if (depSelectedAirport.id !== -1 && depSelectedTests.id !== -1 && isTermsChecked) {
      return true
    }
    return false
  }

  proceedForBookingAppointment() {
    if(this.shouldProceedToBookingAppointment()){
      this.setState({isViewApplicantsAvailabilityDialogOpen: false})
    }
  }

  handleClickOnEvent(event: any){
    if(this.state.userRole === "dpe"){
      this.handleOpenEventDetails(event)
    }else{
      localStorage.setItem("applicantAvailabilityId", event.id)
      this.setState({isViewApplicantsAvailabilityDialogOpen: true,}, () => this.fetchSingleAvailibility(event.id))
    }
  }

  handleOpenEventDetails = (event: any) => {
    this.setState({ 
      availabilityId: event.id,
      selectedTimeSlotForEdit: {starttime: moment(event.start).format("hh:mm A"), endtime: moment(event.end).format("hh:mm A")},
      isViewAvailabiliDialogOpen: true,
      selectedAvailability:{ ...this.state.selectedAvailability, 
      availability: {
        ...this.state.selectedAvailability.availability,
        start_time: moment(event.start).format("hh:mm A"),
        end_time: moment(event.end).format("hh:mm A"),
    } as any} 
    });
    this.fetchAvailabilityDetails(event.id)
  }

  getCategoryApi = async () => {
    this.getCategoryApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.categoryEndPoint
    })
  };

  getCategorySucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const categoryOptions = response.map((category) => ({
         id: category.id.toString(), 
         name: category.name,      
         selected: true 
     }));
     this.setState((prevState) => ({
      ...prevState,
      allCategoryData: categoryOptions,
      filteredCategoryData: categoryOptions,
      initialFilters: {...prevState.initialFilters, categories: categoryOptions}
     }));
 }
 };

  getAirPortsApi = async () => {
    this.getAirPortsForFilterApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.getAirportListEndPoint
    })
  };

  getAirportsFiltersSucessCallBack = (response: Aircraft[]) => {
    const airportsOptions = response.map((item) => ({
      id: item.id.toString(), 
      name: item.name,
      selected: true       
    }));
    this.setState((prevState) => ({
      ...prevState,
        allAirportsForFilter: airportsOptions,
        filteredAirports: airportsOptions,
        initialFilters: {...prevState.initialFilters, airports: airportsOptions}})
      );
      console.log('filteredAirports',this.state.filteredAirports)
}

  getCertificateApi = async () => {
    this.getCertificateApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.gradeOfCertificateEndPoint
    })
  };

  getCertificateSucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const gradeOptions = response.map((item) => ({
         id: item.id.toString(), 
         name: item.name,
         selected: true       
     }));
     this.setState((prevState) => ({
        ...prevState,
        allGradeData: gradeOptions,
        filteredGradeData: gradeOptions,
        initialFilters: {...prevState.initialFilters, grades: gradeOptions}
     }));
 }
  };

  getAirPortClassApi = async () => {
    this.getClassApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.airPortClassEndPoint
    })
  };

  getAirPortClassSucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const classOptions = response.map((classOptions) => ({
         id: classOptions.id.toString(), 
         name: classOptions.name,
         selected: true       
     }));
     this.setState((prevState) => ({
      ...prevState,
      allClassData: classOptions,
      filterClassData: classOptions,
      initialFilters: {...prevState.initialFilters, classes: classOptions}
     }));
 }
 };

  getAircrafttypeApi = async () => {
    this.getAircrafttypeApiCallId = await this.scheduleAvailabilityApiCall({
        contentType: configJSON.exampleApiContentType,
        method: configJSON.getApiMethodType,
        endPoint: configJSON.aircraftTypeEndPoint
    })
  };

  getAircrafttypeSucessCallBack = (response: Aircraft) => {
    if (Array.isArray(response) ) {
     const aircraftTypeOptions = response.map((aircraft) => ({
         id: aircraft.id.toString(), 
         name: aircraft.name,
         selected: true       
     }));
     this.setState((prevState) => ({
      ...prevState,
      allAircrafts: aircraftTypeOptions,
      filteredAircrafts: aircraftTypeOptions,
      initialFilters: {...prevState.initialFilters, aircrafts: aircraftTypeOptions}
     }));
    };
  }

  formatNumericDateToString(date?: string): string {
    if (!date) { return "";}

    const parts = date.split("/");

    if (parts.length !== 3) {
      return "";
    }

    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const dateObj = new Date(year, month, day);

    if (isNaN(dateObj.getTime())) {
      return "";
    }

    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    return dateObj.toLocaleDateString("en-US", options);
  }

  handleBackToViewAvailability(){
    const applicantAvailabilityId = localStorage.getItem("applicantAvailabilityId")
    console.log(applicantAvailabilityId)
    if(applicantAvailabilityId === "0" || !applicantAvailabilityId || this.state.userRole === "dpe") return
    this.setState({isViewApplicantsAvailabilityDialogOpen: true}, () => this.fetchSingleAvailibility(applicantAvailabilityId as any))
  }

  getAvailabilityForDpeSuccessCallBack(responseJson: any) {
    if (!responseJson.errors) {
      const { airports, designations } = responseJson.data
      const { availability_date, time_zone, timeslots, status } = responseJson.data.availability
      this.setState({
        dpeTimeslot: this.formatTimeSlot(timeslots[0]),
        dpeTimezone: time_zone,
        dpeAllAirport: airports,
        dpeAllTest: designations,
        dpeAvailabilityDate: this.formatNumericDateToString(availability_date),
        dpeAvailabilityStatus: status
      })

      if (airports.length === 1) {
        this.setState({ depSelectedAirport: airports[0] })
      }

      if (designations.length === 1) {
        this.setState({ depSelectedTests: designations[0] })
      }
      } else {
        console.log("ERRRRTEST", responseJson.errors);
      }
  }

  scheduleAvailabilityApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    const token = await (localStorage.getItem('accessToken'))
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
        "Content-Type": contentType,
        Token: token
    };
    let accountSetupRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    accountSetupRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    accountSetupRequestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    body &&
    accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            apiType === "" ? JSON.stringify(body) : body
        );
        accountSetupRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(accountSetupRequestMessage.id, accountSetupRequestMessage);
    return accountSetupRequestMessage.messageId;
  };
  
  async editAvailabilityDetails(){
    const token = localStorage.getItem('accessToken');
    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    const data = {
        start_time: this.getAvailabilityStarttime(),
        end_time: this.getAvailabilityEndtime(),
        availability_date: moment(this.state.availabilityDate).format("DD/MM/YYYY"),
        time_zone: this.state.selectedTimeZone,
        airports: this.getEditedAirportForApiBody(),
        designations: this.getEditedTestsForApiBody(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editAvailabilityDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/${this.state.availabilityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async deleteAvailability(){

    const token = localStorage.getItem('accessToken');

    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAvailabilityApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_appointment_management/availabilities/${this.state.availabilityId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleOpenDpePopup = (event:any) => {
    this.setState({ isDpePopupOpen: true, isFiltersDialogOpen: false });
    this.filterGet()
  };

  handleCloseDpePopup = () => {
    this.setState({ isDpePopupOpen: false });
    this.availabilityApplicant(this.state.startDate, this.state.endDate)
    window.location.reload();
  };
  handleCloseDpePopup24 = () => {
    this.setState({ isFiltersDialogOpen: true,isDpePopupOpen:false})  };
  

  // Customizable Area End
}
