// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
} from "@mui/material";
import SchedulingController, { Props } from "../SchedulingController";
import { leftArrow, money, pdf_icon } from "../assets";
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Link } from 'react-router-dom';

export default class TestDetails extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }

    renderDataCard(iCon: React.ReactNode, title: string, value: string, showValue: boolean = true, showBottomBorder: boolean = false, valueIcon?: string) {
        return (
            <Box sx={[webStyle.dataCardContainer, showBottomBorder && webStyle.dataCardContainer_border]}>
                <Box sx={webStyle.dataCardIconContainer}>
                    {iCon}
                </Box>
                <Box sx={webStyle.dataCardTextContainer}>
                    <Typography sx={webStyle.dataCardTitle}>{title}</Typography>
                    {
                        showValue && <Box sx={webStyle.valueContainer}>
                            {
                                valueIcon && <Typography style={webStyle.dataCardValueIcon}>$</Typography>
                            }
                            <Typography sx={webStyle.dataCardValue}>{value}</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        )
    }

    renderDesignation() {
        return this.renderDataCard(<AirplaneTicketOutlinedIcon style={webStyle.icons} /> , "Designation", "Private - Airplane - Single Engine Land", true, true)
    }

    renderExamCost() {
        return this.renderDataCard(<img src={money} alt="airplane" style={webStyle.icons}/>, "Exam cost", "1,000.00", true, true, "attach-money")
    }

    renderPaymentMethods() {
        return this.renderDataCard(<PaymentOutlinedIcon style={webStyle.icons} />, "Accepted payment methods", "Credit Card, Cash", true, true)
    }

    renderInstructions() {
        return this.renderDataCard(<DescriptionOutlinedIcon style={webStyle.icons} />, "Instructions", "", false, false, "")
    }

    renderSinglePdf(fileName: string, fileSize: number, fileUrl: string) {
        return (
            <Box sx={webStyle.pdfFileContainer}>
                <Box sx={webStyle.pdfFileIconContainer}>
                    <img src={pdf_icon} alt="airplane" style={webStyle.icons}/>
                </Box>
                <Box>
                    <Typography style={webStyle.pdfFileName}>{fileName}</Typography>
                    <Typography style={webStyle.pdfFileSize}>{fileSize} KB</Typography>
                </Box>
            </Box>
        )
    }

    renderInstructionsData() {
        return (
            <Box style={webStyle.instructionDetailsContainer}>
                <Typography style={webStyle.instructionsDetailsText}>
                    1. Flight Proficiency – Ensure your skills are sharp for handling a variety of flight maneuvers.
                </Typography>

                <Typography style={webStyle.instructionsDetailsText}>
                    2. Aircraft Systems – Review your knowledge of single-engine aircraft systems, including engine operations, fuel management, and avionics.
                    Be ready to fly in various conditions and manage in-flight emergencies.
                </Typography>
                <Typography style={webStyle.instructionsDetailsText}>
                     3. Regulatory Knowledge – Brush up on FAR Part 91, flight planning, and weight & balance for single-engine operations.
                    Remember to bring your logbook, valid medical certificate, pilot’s license, and a government-issued ID. Looking forward to a smooth and thorough evaluation!
                </Typography>
    
                <Box style={webStyle.allPdfListing}>
                    {this.renderSinglePdf("instruction.pdf", 128, "")}
                    {this.renderSinglePdf("tobeSigned.pdf", 128, "")}
                </Box>
            </Box>
        )
    }
  
  render() {
    
    return (
        <>
        <Box sx={webStyle.headerContainer} data-test-id = "test-details-page">
          <Box sx={webStyle.pageHeader}>
              <Link to={"/calendar"}><img data-test-id = "back-to-view-availability" onClick={() => this.handleBackToViewAvailability()} src={leftArrow} style={webStyle.leftArrow} /></Link>
              <Typography sx={webStyle.headerText}>Test details</Typography>
          </Box>
        </Box>
        
        <Box style={{padding:'50px 50px 50px 50px'}} >
                <Box sx={webStyle.contentContainer} >
                    <Grid container spacing={2} >
                        <Grid paddingTop={"20px"} item xs={12} sm={12} lg={12} md={12} >
                            {this.renderDesignation()}
                        </Grid>
                        <Grid paddingTop={"20px"} item xs={12} sm={12} lg={6} md={6} >
                            {this.renderExamCost()}
                        </Grid>
                        <Grid paddingTop={"20px"} item xs={12} sm={12} lg={6} md={6} >
                            {this.renderPaymentMethods()}
                        </Grid>
                        <Grid paddingTop={"20px"} item xs={12} sm={12} lg={12} md={12} >
                            {this.renderInstructions()}
                        </Grid>
                    </Grid>
                    {this.renderInstructionsData()}
                </Box>
        </Box>
      </>
    );
  }
}

const webStyle = {
    pageHeader: {
        display:'flex',
        alignItems:'center',
        gap:'20px'
    },
    allPdfListing: {
       margin:"20px 0px",
       display:"flex"
    },
    instructionDetailsContainer: {
        padding: "20px",
        backgroundColor: "#E0F2F850",
    },
    instructionsDetailsText: {
        color: "#001C55",
        fontSize: "16px",
        fontWeight: "400",
        margin:"3px 0px"
    },
    icons:{color:"#94A3B8", cursor:"pointer"},
    dataCardValueIcon: {
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: "400",
        marginRight: "5px"
    },
    dataCardTextContainer: {
        marginLeft: "15px"
    },
    dataCardTitle: {
        fontSize: "14px",
        fontWeight: "500",
        color: "#94A3B8",
    },
    dataCardIconContainer: {
        backgroundColor: "#E0F2F8",
        height: "36px",
        width: "36px",
        flexDirection: "row",
        display:"flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px"
    },
    dataCardContainer:{
        display:"flex",
        padding:"8px 20px 20px 20px",
        flexDirection: "row",
        alignItems: "center"
    },
    headerContainer: {
        display: "flex",
        height: "60px",
        padding: "10px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    contentContainer: {
        padding: "20px 50px 10px 50px",
        alignItems: "center",
        background: "#ffffff",
        justifyContent:"space-between"
    },
    dataCardContainer_border:{
        borderBottom:"1px solid #E2E8F0",
    },
    headerText: {
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "22px",
        color: "#0074BF",
    },
    leftArrow:{ 
        marginRight: "10px", 
        height: '24px', 
        width: '24px', 
        color: "#0074BF" 
    },
    dataCardValue: {
        fontSize: "16px",
        fontWeight: "400",
        color: "#001C55",
    },
    valueContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    pdfFileContainer: {
        display: "flex", 
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#ffffff",
        padding: "10px",
        margin: "8px 15px 8px 0px", 
        borderRadius: "20px",
        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
        width: "315px" 
    },
    pdfFileIconContainer: {
        backgroundColor: "#E0F2F8",
        height: 30,
        width: 30,
        borderRadius: 8,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "20px"
    },
    pdfFileName: {
        fontSize: "14px",
        fontWeight: "400",
        color: "#0F172A",
    },
    pdfFileSize: {
        fontSize: "12px",
        fontWeight: "400",
        color: "#94A3B8",
    }
}
// Customizable Area End
