import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
import { toast } from "react-toastify";
interface Option {
    id: string;
    name: string;
}
interface AircraftTypeItem {
    id: string;
    value: string;
}

  interface ErrorToken {
    token: string;
}

interface ErrorResponse {
    errors: ErrorToken[];
}

interface ExamData {
    exam_cost: string;
    accepted_payment_methods: string;
    instructions: string;
    id: number;
    designation: string;
    dpe_account_id: number;
    created_at: string; 
    updated_at: string; 
    mark_as_deleted: boolean;
  }
  
  
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    selectedForm: string;
    accordform1Expanded: boolean;
    accordform2Expanded: boolean;
    step1Form: {
        airports: string[];
        options: Option[];
    }
    step2Form: {
        firstAccordionExpanded: boolean
        secondAccordionExpanded: boolean
        gradeOfCirtificates: string[];
        category: string[];
        class: string[];
        aircraftTypes: string[];
        addedAirCraftTypes: AircraftTypeItem[];
        gradeOptions: Option[];
        categoryOptions: Option[];
        classOptions: Option[];
        aircraftTypeOptions: Option[]
    }
    activeStep: number,
    step3Form: {
        designation: string[];
        examCost: string;
        paymentMethods: string;
        instructions: string;
        uploadedFiles: File[];
        designationOption: Option[],
        uploadedData: any
        currentdata:{
        designation: string[];
        examCost: string;
        paymentMethods: string;
        instructions: string;
        uploadedFiles: File[];
        }
    },
    isMobile: boolean;
    // Customizable Area End
}
interface SS {
    // Customizable Area Start
    id: number;
    // Customizable Area End
}

export default class AccountSetUpController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    private mediaQueryListener: MediaQueryList;
    getAirportsApiCallId: string = "";
    getCertificateApiCallId : string = "";
    getCategoryApiCallId : string = "";
    getClassApiCallId : string = "";
    getAircrafttypeApiCallId : string = "";
    postAircrafttypeApiCallId : string = "";
    postInstructionsApiCallId : string = "";
    CreateDesignationApiCallId : string = "";
    // Customizable Area End
    getDesignationApiCallId: string = "";
    postDesignationApiCallId: string = "";
    postAirportsApiCallId: string = "";
    deleteDesignationApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
      // Customizable Area Start
        this.state = {
            selectedForm: 'form1',
            accordform1Expanded: false,
            accordform2Expanded: false,
            step1Form: {
                airports: [],
                options: []
            },
            step2Form: {
                firstAccordionExpanded: true,
                secondAccordionExpanded: true,
                gradeOfCirtificates: [],
                category: [],
                class: [],
                aircraftTypes: [],
                addedAirCraftTypes: [],
                gradeOptions: [],
                categoryOptions: [],
                classOptions: [],
                aircraftTypeOptions: [],
            },
            activeStep: 0,
            step3Form: {
                designation: [],
                examCost: "",
                paymentMethods: "",
                instructions: "",
                uploadedFiles: [],
                designationOption: [],
                uploadedData: [],
                currentdata : {designation: [],
                    examCost: "",
                    paymentMethods: "",
                    instructions: "",
                    uploadedFiles: [],}
            },
            isMobile: window?.matchMedia('(max-width: 900px)').matches,
        };
        this.mediaQueryListener = window.matchMedia("(max-width: 900px)");
        // Customizable Area End
    }
       // Customizable Area Start
    async receive(from: string, message: Message) {
        this.handleApiResponse(message)
    }

    async handleApiResponse(message: any) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        if (!responseJson) return;
    
        if (!responseJson.errors) {
            this.processSuccessResponse(apiRequestCallId, responseJson);
        } else {
            this.responseFailureCell(apiRequestCallId, responseJson);
        }
    }

    processSuccessResponse(apiRequestCallId: any, responseJson: any) {
        if (this.getDesignationApiCallId === apiRequestCallId) {
            this.updateDesignationOptions(responseJson);
        } else if (this.postDesignationApiCallId === apiRequestCallId) {
            this.handlePostDesignation();
        } else if (this.postAirportsApiCallId === apiRequestCallId) {
            this.handlePostAirports();
        }
    
        this.responseSucessCell(apiRequestCallId, responseJson);
    }
    
    updateDesignationOptions(responseJson: any) {
        const options = responseJson.message !== "No designation instructions found for the given ID"
            ? responseJson.map((item: any) => ({
                id: item.id.toString(),
                label: item.designation
            }))
            : [];
    
        this.setState((prev) => ({
            ...this.state,
            step3Form: {
                ...prev.step3Form,
                designationOption: options,
                designation: options.length > 0 ? [...this.state.step3Form.designation, options[0].id] : this.state.step3Form.designation
            }
        }));
    }
    
    handlePostDesignation() {
        const token = localStorage.getItem('accessToken');
        this.getDesignationRequest(token);
        this.handleNext();
    }

    async handlePostAirports() {
        const token = localStorage.getItem('accessToken');
        this.getAirportsRequest(token);
        this.handleNext();
    }

    getlabels = (options: Option[], selectedOptions: string[]) => {
        return selectedOptions
            .map((id: string) => options.find((option: Option) => option.id === id)?.name)
            .filter(Boolean) // Removes undefined values if an ID doesn't match
            .join(', ');
    }
    handleFileSelect = (fileList: FileList | null) => {
        if (!fileList) return;
        const newFiles = Array.from(fileList)
        this.setState(prevState => ({
            ...prevState,
            step3Form: {
                ...prevState.step3Form,
                uploadedFiles: [...prevState.step3Form.uploadedFiles, ...newFiles]
            }
        }));
    };

    handleNext = () => {
        this.setState(prevState => ({
            activeStep: Math.min(prevState.activeStep + 1, 2)
        }));
    };
    deleteAircraftTest = (id: string) => {
        this.setState((state) => ({
            step2Form: {
                ...state.step2Form,
                addedAirCraftTypes: state.step2Form.addedAirCraftTypes.filter(
                    (item) => item.id !== id
                ),
            },
        }),
            () => {
                if (this.state.step2Form.addedAirCraftTypes.length == 0) {
                    this.setState((state) => ({
                        step2Form: {
                            ...state.step2Form,
                            gradeOfCirtificates: [],
                            category: [],
                            class: [],
                            aircraftTypes: [],
                        },
                    }))
                }
            }
        );
    }
    handleAccordform1Expanded = (name: string) => {
        if (name === "accordform1Expanded") {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform1Expanded: !this.state.accordform1Expanded
                },
            }));
        } else {
            this.setState((state) => ({
                step1Form: {
                    ...state.step1Form,
                    accordform2Expanded: !this.state.accordform2Expanded
                },
            }));
        }
    }

    getLabelFromId(id: string, options: Option[]): string {
        const option = options.find(opt => opt.id === id);
        return option ? option.name : '';
    }

    generateCombinations() {
        const { gradeOfCirtificates, category, class: classType, aircraftTypes,
            gradeOptions, classOptions, categoryOptions, aircraftTypeOptions
        } = this.state.step2Form;
        let combinations: { id: string; value: string }[] = [];
        let id = 1;
        const selectedGrades = gradeOfCirtificates?.map(id => this.getLabelFromId(id, gradeOptions));
        const selectedCategories = category.map(id => this.getLabelFromId(id, categoryOptions));
        const selectedClasses = classType.map(id => this.getLabelFromId(id, classOptions));
        selectedGrades.forEach(grade => {
            selectedCategories.forEach(category => {
                selectedClasses.forEach(classType => {
                    combinations.push({
                        id: id.toString(),
                        value: `${grade} - ${category} - ${classType}`
                    });
                    id++;
                });
            });
        });
        return combinations;
    }

    handleStep1Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step1Form: {
                ...state.step1Form,
                [name]: value,
            },
        }));
    }
    handleStep2Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step2Form: {
                ...state.step2Form,
                [name]: value,
            },
        }), () => {
            const form = this.state.step2Form;
            if (form.gradeOfCirtificates.length * form.category.length *
                form.class.length > 0) {
                this.setState(state => ({
                    ...state,
                    step2Form: { ...state.step2Form, addedAirCraftTypes: this.generateCombinations() }
                }));
            }else{
                this.setState(state => ({
                    ...state,
                    step2Form: { ...state.step2Form, addedAirCraftTypes: this.generateCombinations() }
                }))
            }
        });
    }
    handleStep3Change = (name: string, value: string[]) => {
        this.setState((state) => ({
            ...state,
            step3Form: {
                ...state.step3Form,
                [name]: value,
            },
        }));
    }
    handleStep3InputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event?.target
        if  (name == "examCost"){
            const numericValue = value.replace(/[^0-9.,]/g, '');
            const formattedValue = `$ ${numericValue}`;
            this.setState((state) => ({
                ...state,
                step3Form: {
                    ...state.step3Form,
                    [name]: formattedValue,
                },
            }));
        }else if(name == "paymentMethods"){
       const textOnlyValue = value.replace(/[^a-zA-Z\s,]/g, '');
            this.setState((state) => ({
                ...state,
                step3Form: {
                    ...state.step3Form,
                    [name]: textOnlyValue,
                },
            }));
        }else{
            this.setState((state) => ({
                ...state,
                step3Form: {
                    ...state.step3Form,
                    [name]: value,
                },
            }));
        }  
    }

    handleSubmit = () => {
        const currentIndex = this.state.step3Form.designationOption.findIndex(opt => opt.id === this.state.step3Form.designation[this.state.step3Form.designation.length - 1]);
        const nextIndex = currentIndex + 1;
        if (this.state.step3Form.designationOption.length - 1 === currentIndex) {
            this.props.navigation.navigate('Profile');
        }
        this.setState((state) => ({
            ...state,
            step3Form: {
                ...state.step3Form,
                designation: nextIndex < this.state.step3Form.designationOption.length
                    ? [this.state.step3Form.designationOption[nextIndex].id]
                    : this.state.step3Form.designation,
                uploadedData: [
                    ...state.step3Form.uploadedData,
                    {
                        designation: state.step3Form.designation,
                        examCost: state.step3Form.examCost,
                        paymentMethods: state.step3Form.paymentMethods,
                        instructions: state.step3Form.instructions,
                        uploadedFiles: state.step3Form.uploadedFiles,
                    }
                ],
                examCost: "",
                paymentMethods: "",
                instructions: "",
                uploadedFiles: [],
                currentdata: {
                    designation: state.step3Form.designation,
                    examCost: state.step3Form.examCost,
                    paymentMethods: state.step3Form.paymentMethods,
                    instructions: state.step3Form.instructions,
                    uploadedFiles: state.step3Form.uploadedFiles,
                }
            },
        }), ()=> this.instructionsApiCall())
    }

    handlePrevious = () => {
        this.setState(prevState => ({
            activeStep: Math.min(prevState.activeStep - 1, 2)
        }));
    }

    handleAccordionChange = (type: string, isExpanded: boolean) => {
        this.setState((state) => ({
            ...state,
            step2Form: {
                ...state.step2Form,
                [type]: isExpanded,
            },
        }));
    };

    handleScreenSizeChange = (event: MediaQueryListEvent) => {
        this.setState({ isMobile: event.matches });
      };

    async componentDidMount() {
        let token = localStorage.getItem('accessToken');
        this.mediaQueryListener.addEventListener("change", this.handleScreenSizeChange);
        this.getAirportsRequest(token);
        this.getDesignationRequest(token);
        this.getCertificateApi()
        this.getCategoryApi()
        this.getAirPortClassApi()
        this.getAircrafttypeApi()
    }

    async componentWillUnmount() {
        this.mediaQueryListener.removeEventListener("change", this.handleScreenSizeChange);
      };

    getAirportsRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAirportsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAirportUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDesignationRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDesignationApiCallId = requestMessage.messageId
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDesignationUrl
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    postDesignationRequest = async() => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            designations: this.state.step2Form.addedAirCraftTypes.map((item) => ({ designation: item.value }))
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_categories/designation_instructions/create_multiple"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    postAirportsRequest = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            airports: this.state.step1Form.airports.map((item) => ({ service_subcategory_id: item }))
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postAirportsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_categories/dpe_airport_settings/create_multiple"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    deleteDesignationRequest = (currentTestId: string) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };
        let token = localStorage.getItem('accessToken');
        const data = {
            token: token,
            designations: this.state.step2Form.addedAirCraftTypes.map((item) => ({ designation: item.value }))
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteDesignationApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_categories/designation_instructions/${currentTestId}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;

    };

    accountSetupApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        apiType?: string;
        type?: string;
    }) => {
        const token = await (localStorage.getItem('accessToken'))
        let { contentType, method, endPoint, body, apiType } = valueData;
        let header = {
            "Content-Type": contentType,
            Token: token
        };
        let accountSetupRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        body &&
        accountSetupRequestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                apiType === "" ? JSON.stringify(body) : body
            );
            accountSetupRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(accountSetupRequestMessage.id, accountSetupRequestMessage);
        return accountSetupRequestMessage.messageId;
    };

    responseSucessCell = async (apiRequestCallId: string, responseJson: Option & ExamData & Option[]) => {
        if (apiRequestCallId === this.getCertificateApiCallId) {
            this.getCertificateSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCategoryApiCallId) {
            this.getCategorySucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getClassApiCallId) {
            this.getAirPortClassSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAircrafttypeApiCallId) {
            this.getAircrafttypeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postAircrafttypeApiCallId) {
            this.postAircrafttypeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.postInstructionsApiCallId) {
            this.instructionsApiSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAirportsApiCallId) {
            this.getAirportsSucessCallBack(responseJson);
        }
    };

    responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
        if (apiRequestCallId === this.getCertificateApiCallId) {
            this.getCertificateFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getCategoryApiCallId) {
            this.getCategoryFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getClassApiCallId) {
            this.getAirPortClassFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAircrafttypeApiCallId) {
            this.getAircrafttypeFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.postAircrafttypeApiCallId) {
            this.postAircrafttypeFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.postInstructionsApiCallId) {
            this.instructionsApiFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.getAirportsApiCallId) {
            this.getAirportsFailureCallBack(responseJson);
        }
    };

    getAirportsSucessCallBack = (response: Option[]) => {
        this.setState({
            ...this.state,
            step1Form: {
                airports: this.state.step1Form.airports,
                options: response
            }
        });
    }

    getAirportsFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    }


    getCertificateApi = async () => {
        this.getCertificateApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.gradeOfCertificateEndPoint
        })
    };

    getCertificateSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        const gradeOptions = response.map((item) => ({
            id: item.id.toString(), 
            name: item.name,       
        }));
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                gradeOptions: gradeOptions, 
            },
        }));
    }
    };

    getCertificateFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    getCategoryApi = async () => {
        this.getCategoryApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.categoryEndPoint
        })
    };

    getCategorySucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        const categoryOptions = response.map((category) => ({
            id: category.id.toString(), 
            name: category.name,       
        }));
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                categoryOptions: categoryOptions, 
            },
        }));
    }
    };

    getCategoryFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    getAirPortClassApi = async () => {
        this.getClassApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.airPortClassEndPoint
        })
    };

    getAirPortClassSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        const classOptions = response.map((classOptions) => ({
            id: classOptions.id.toString(), 
            name: classOptions.name,       
        }));
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                classOptions: classOptions, 
            },
        }));
    }
    };

    getAirPortClassFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    getAircrafttypeApi = async () => {
        this.getAircrafttypeApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.validationApiMethodType,
            endPoint: configJSON.aircraftTypeEndPoint
        })
    };

    getAircrafttypeSucessCallBack = (response: Option) => {
       if (Array.isArray(response) ) {
        const aircraftTypeOptions = response.map((aircraft) => ({
            id: aircraft.id.toString(), 
            name: aircraft.name,       
        }));
        this.setState((prevState) => ({
            step2Form: {
                ...prevState.step2Form,
                aircraftTypeOptions: aircraftTypeOptions, 
            },
        }));
    };
}

    getAircrafttypeFailureCallBack = (response: ErrorResponse) => {
        toast.error(response.errors[0].token)
    };

    handleAircraft = async (value: string) => {
        const body = {
            category_name: "Aircraft",
            subcategory_name: value
        }
        this.postAircrafttypeApiCallId = await this.accountSetupApiCall({
            contentType: configJSON.contentTypeApiAddDetail,
            method: configJSON.apiMethodTypeAddDetail,
            endPoint: configJSON.postAirCraftEndPoint,
            body: JSON.stringify(body),
        });
    };

    postAircrafttypeSucessCallBack = (response: Option) => {
        if(response){
            this.getAircrafttypeApi()
        }
   }
 
     postAircrafttypeFailureCallBack = (response: ErrorResponse) => {
         toast.error(response.errors[0].token)
     };

    instructionsApiCall = async () => {
        const currentdata: {designation: string[],examCost: string, paymentMethods:string,instructions:string,uploadedFiles: File[]} = this.state.step3Form.currentdata
        let formdata = new FormData();
        if( Array.isArray(currentdata.uploadedFiles)){
            currentdata.uploadedFiles.forEach((upload: File) => {
                formdata.append("pdf_files[]", upload);
            });
        }
        formdata.append('designation_instruction[exam_cost]', currentdata.examCost.replace('$', '').trim());
        formdata.append('designation_instruction[accepted_payment_methods]', currentdata.paymentMethods);
        formdata.append('designation_instruction[instructions]', currentdata.instructions);
        this.postInstructionsApiCallId = await this.accountSetupApiCall({
            method: configJSON.putMethod,
            endPoint: `${configJSON.instructionsEndPoint}${currentdata.designation[0]}`,
            body: formdata,
        });
    };

    instructionsApiSucessCallBack = (response: ExamData) => {
        this.state.step3Form.designationOption.some((option) => option.id === String(response.id));
    };

    instructionsApiFailureCallBack = (response: ErrorResponse) => {
     toast.error(response.errors[0].token)
    };


    handleLogout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("role");
       this.props.navigation.navigate("Login")
    }
    
    // Customizable Area End
}
