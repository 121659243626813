// Customizable Area Start
import React from "react";
import {

  MenuItem, Select, FormControl, IconButton,
  styled,
  Box,
  Dialog,
  DialogContent,
  Typography,
  Button,
  DialogTitle,
  TextField,
  Switch,
  BoxProps,
  Snackbar,
  Grid,
} from "@mui/material";
import { Link } from 'react-router-dom';
import './calendarStyles.css';
import "react-calendar/dist/Calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CustomWeekView from "../../../blocks/scheduling/src/CustomWeekView.web";


import { est, day, day3, week, month, schedule, deleteIcon, upArrow, downArrow, checkedBox, unCheckedBox, greenTick, cross, booked, canceled, completed, expired, missed, openForBooking, dpe_icon, radio_unselected, radio_selected } from './assets'
import CloseIcon from "@mui/icons-material/Close";
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import { Calendar as Bcalendar } from "@builder/component-library";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PublicIcon from '@mui/icons-material/Public';
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EastIcon from '@mui/icons-material/East';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SchedulingController, {
  Props,
} from "./SchedulingController";
import { StyleSheet, TouchableOpacity, View, Image } from "react-native";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { calender } from "../../../../packages/blocks/navigationmenu/src/assets";


interface CustomBoxSnackbarProps extends BoxProps {
  alertType?: 'success' | 'error';
}

export default class BigCalender extends SchedulingController {
  constructor(props: Props) {
    super(props);
  }
  eventStyleGetter = (event: any) => {
    const isBooked = Boolean(event.booked_status);
    
    const backgroundColor = isBooked ? "#004E64" : "white";
    const color = isBooked ? "white" : "#004E64";
  if(this.state.view !== "agenda"){
    return {
      style: {
        backgroundColor,
        color, 
        border: "1px solid #004E64",
        display: "flex",
        alignItems: "center",
        borderRadius: "6px",
        padding: "2px 5px",
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        
        pointerEvents:"auto" as any,
        cursor:"pointer"
      },
    };
  }
  else {
    return {
      style: {
        backgroundColor: "white",
        color: "#004E64",
        display: "flex",
        alignItems: "center",
        borderRadius: "6px",
        padding: "5px",
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginBottom: "5px",
        
      },
    };
  }
  };

 
  CustomToolbar = ({ label, onView, onNavigate, views }: any) => {
    const viewIcons: any = {
      day: day,
      "3 day": day3,
      week: week,
      month: month,
      schedule: schedule,
    };
    return (
      <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

        <div >
          <div className="custom-label" >
            {label}
          </div>
          <div style={{ fontSize: '12px', fontFamily: '#94A3B8', color: '#94A3B8', display: 'flex', gap: '10px', marginTop: '9px' }}>
            <img src={est} style={{ width: '14px', height: '14px' }} />
            Eastern Standard Time (EST)
          </div>

        </div>
        <div style={{ display: 'flex', }}>
          <IconButton onClick={() => onNavigate("PREV")} aria-label="Previous month">
            <KeyboardArrowLeftOutlinedIcon />
          </IconButton>


          <IconButton onClick={() => onNavigate("NEXT")} aria-label="Next month">
            <KeyboardArrowRightOutlinedIcon />
          </IconButton>

          <FormControl  >

          
   <Select
  data-test-id="handleViewChange"
  value={this.state.view}
  onChange={this.handleViewChange}
  IconComponent={KeyboardArrowDownOutlinedIcon}
  className="custom-dropdown"
>
  {[ "day","3 day", "week", "month"].map((viewOption) => (
    <MenuItem key={viewOption} value={viewOption} className="custom-menu-item">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "14px",
          fontFamily: "Montserrat",
          color: "#3C3E49",
        }}
      >
        <img
          src={viewIcons[viewOption] || month}
          style={{ width: "16px", height: "16px", marginRight: "8px" }}
          alt={viewOption}
        />
        {viewOption.charAt(0).toUpperCase() + viewOption.slice(1)}
      </div>
    </MenuItem>
  ))}
</Select>

          </FormControl>
        </div>
      </div>
    );
  };
lastEventDate: string | null = null;


  handleViewChange = (event: any) => {
    this.setState({ view: event.target.value });
  };


  renderCalender(){
    const {isCalenderOpen, availabilityDate, todayDate} = this.state
    return <><View style={styles.calenderContainer}>
          <TouchableOpacity data-test-id="open-calender-btn"
            onPress={() => this.toggleCalenderOpen()}
          ><View style={styles.subContainer2}>
              <View style={{ flexDirection: "row" }}>
                <TodayOutlinedIcon style={webStyle.icons}/>
                <View style={{ marginLeft: 25 }}>
                  <Typography
                    style={{
                      color: isCalenderOpen ? "#0074BF" : "#0F172A",
                      fontWeight: "500",
                      fontSize: 16,
                    }}>
                    {this.formatDate(new Date(availabilityDate as any))}
                  </Typography>
                </View>
              </View>
              <Box sx={{cursor: "pointer"}}>
              <Image
                source={isCalenderOpen ? upArrow : downArrow}
                style={styles.arrowStyle}
              />
              </Box>
              
            </View>
          </TouchableOpacity>
        </View>
        {isCalenderOpen && (
            <CustomBox >
              <Bcalendar
                style={{maxWidth:"100%"}}
                onDayPress={(day: { dateString: any }) => {
                  this.setState({availabilityDate: day.dateString, isCalenderOpen: false, todayDate: ""});
                }}
                minDate={new Date().toISOString().split("T")[0]}
                currentDate={new Date()}
                test-test-id="calender-basic"
                markedDates={{
                  [availabilityDate]: {
                    selected: true,disableTouchEvent: true, selectedColor: "#5274B5", selectedTextColor: "white",
                  },
                  [todayDate]: {
                    selected: true,
                    selectedColor: todayDate==="" ? "#fff" :"#5274B5",
                    selectedTextColor: "white",
                    disableTouchEvent: true,
                  },
                }}
                theme={{selectedDayBackgroundColor: "#5274B5",selectedDayTextColor: "white",dayTextColor: "#64748B",
                }}
              />
            </CustomBox>
          )}
    </>
  }

  renderNotifyAllSwitch() {
    const { shouldNotifyAlluser } = this.state;
    return (
    <View  style={styles.borderBottom}>
      <Box data-test-id="calenderopenbtn" >
        <View style={styles.subContainerNotify}>
          <View   style={styles.flexCenter}>
            <NotificationsOutlinedIcon   style={webStyle.icons} />
            <View   style={{ marginLeft: 25 }}>
              <Typography  style={styles.notifyAll}> Notify all on waitlist </Typography>
            </View>
          </View>
          <Switch
            onChange={(event: any) => 
              this.handleToggleSwitch(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
            data-test-id="shouldNotifyAlluser"
            checked={shouldNotifyAlluser}
          />
        </View>
      </Box>

    </View>
  )}


  renderSelectAirportView() {
    const { isSelectAirportOpen, allAirports,} = this.state
    return(
      <View style={styles.borderBottom}> <TouchableOpacity data-test-id="select-airport"
        onPress={() => this.toggleSelectAirport()}>
        <View  
            style={styles.subContainer2}>
          <View  
             style={styles.flexCenter}>
            <FlightTakeoffIcon style={webStyle.icons} />
            <View 
                style={{ marginLeft: 25 }}>
              <Typography
                style={{fontSize: 16,color: this.getColorEdit(),fontWeight: "500",}}
              >{this.state.editselectedAirports && this.getAirportContainerPlaceholderEdit()}</Typography>
            </View>
          </View>
          <Box sx={{cursor: "pointer"}}><Image style={styles.arrowStyle} source={isSelectAirportOpen ? upArrow : downArrow}/></Box>
        </View>
      </TouchableOpacity>
      {isSelectAirportOpen 
        && <View style={styles.selectAirport}>
        <TouchableOpacity
          style={styles.selectAllAirport}
              data-test-id="selectAllAirports"
          onPress={() => this.toggleSelectAllAirportsEdit()}
        >
          <Image
          style={styles.allCheckImage}
          source={this.handleAllCheckIconEdit()}/>
          <Typography style={styles.airportText}>
            All airports</Typography>
        </TouchableOpacity>
        {allAirports.length >= 1 &&
          allAirports.map((item, index) => (
            <TouchableOpacity
              key={item.service_subcategory_id}
              data-test-id={`selectSingleAirport-${index}`}
              onPress={() => this.handleSelectAirportEdit(item)}
              style={styles.selectAllAirport} >
              <Image source={
                this.handleAirportCheckboxEdit(item.service_subcategory_id) ? checkedBox
                  : unCheckedBox
              } style={styles.checked}
            /> 
              <Typography style={styles.subCate}> {item.service_subcategory_name} </Typography>
            </TouchableOpacity>
          ))}
        <TouchableOpacity
          data-test-id="edit-airports"
          onPress={() => this.props.navigation.navigate("AirportSettings")}
          style={styles.selectAllAirport}
        >
          <EditOutlinedIcon name="edit" style={styles.editIcon} />
          <Typography style={styles.editAirport}>Edit airports</Typography>
        </TouchableOpacity>
      </View>}
    </View>
    )
  }


  renderSelectTestsView() {
    const { allTests, isSelectTestTypeOpen} = this.state
    return (
      <View style={styles.borderBottom}>
        <TouchableOpacity
          data-test-id="select-tests"
          onPress={() => this.toggleSelectTest()}
        >
          <View style={styles.subContainer2}>
            <View style={styles.flexCenter}>
              <AirplaneTicketOutlinedIcon style={webStyle.icons} />
              <View style={{ marginLeft: 25 }}>
                <Typography
                  style={{fontSize: 16,
                    color: this.getSelectedTestColorEdit(),
                    fontWeight: "500",
                  }}
                >
                  {this.getTestContainerPlaceholderEdit()}
                </Typography>
              </View>
            </View>
            <Box sx={{cursor: "pointer"}}>
            <Image style={styles.arrowStyle}
              source={isSelectTestTypeOpen ? upArrow : downArrow}
            />
            </Box>
           
          </View>
        </TouchableOpacity>
        {
          isSelectTestTypeOpen && <View style={styles.padding}>
            <TouchableOpacity
              data-test-id="selectAllTests"
              onPress={() => this.toggleSelectAllTestsEdit()}
              style={styles.selectAllTest}
            >
              <Image
                source={this.handleAllTestCheckIconEdit()}
                style={styles.checked}
              />
              <Typography style={styles.airportText}>All tests</Typography>
            </TouchableOpacity>
            {allTests.length >= 1 &&
              allTests.map((item, index) => (
                <TouchableOpacity
                  key={item.service_subcategory_id}
                  style={styles.singleTest}
                  data-test-id={`select-single-test-${index}`}
                  onPress={() => this.handleSelectTestsforedit(item)}
                >
                  <Image  source={ this.handleTestCheckboxforedit(item.id) ? checkedBox : unCheckedBox}
                    style={styles.checked}
                  />

                  <Typography style={styles.airportText}>{item.designation ?? item.service_subcategory_name} </Typography>
                </TouchableOpacity>
              ))}
              <TouchableOpacity
                data-test-id="redirectToEditTests" style={styles.settings}
                onPress={() => this.props.navigation.navigate("TestSettings")}
              >
              <EditOutlinedIcon name="edit" style={styles.editIcon} />
              <Typography style={styles.editAirport}>Edit tests</Typography>
            </TouchableOpacity>
          </View>}</View>)
  }

  renderSingleTimeSlot(time:any, index: number, isEdit:boolean){
    return <>
      <View key={index} data-test-id="dateContainer"
        style={styles.timeSlots}
      >
        <View style={styles.subContainer} >
          <View style={styles.timePickerContainer}>
            <Box data-test-id={`timePicker${index}`} onClick={() => { this.timePickerInputRefs[`starttime_${index}`]?.click()}}><AccessTimeIcon style={webStyle.icons}/>
            </Box>
            <Box sx={{ cursor: "pointer" }} style={{marginLeft:"10px"}}>
              <LocalizationProvider 
                  dateAdapter={AdapterMoment}>
                <MobileTimePicker
                  onChange={(date) => {
                      this.handleUpdateTimeSlotForEdit("starttime", date as any);
                    }}
                  data-test-id="txtInputAvailableToStartTime"
                  ampm={false}
                  value={moment(time.starttime ?? "10:00", "HH:mm").toDate()}
                  renderInput={(params) => (
                    <CustomTextField 
                      {...params} 
                      sx={{ cursor: "pointer" }}
                      inputRef={(input:any) => {this.timePickerInputRefs[`starttime_${index}`] = input;}}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box onClick={() => {
                this.timePickerInputRefs[`starttime`]?.click();}}
              data-test-id={`open-time-picker-starttime`}
              style={styles.flexContainer}
            >
              <Box sx={{cursor: "pointer"}}><Image style={styles.arrowStyle}source={downArrow} /></Box>
            </Box>
          </View>

          <Box><EastIcon style={styles.eastIcon} /></Box>
          <View style={styles.timePickerContainer}>
            <Box data-test-id={`time-picker-endtime`}
              onClick={() => { this.timePickerInputRefs[`endtime`]?.click(); }}> <AccessTimeIcon style={webStyle.icons}/>
            </Box>
            <Box sx={{ cursor: "pointer" }} style={{marginLeft:"10px"}}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileTimePicker
                value={moment(time.endtime ?? "11:00", "HH:mm").toDate()}
                data-test-id="txtInputAvailableToEndTime"
                ampm={false}
                onChange={(date) => {
                  if (date) { this.handleUpdateTimeSlotForEdit("endtime", date as any)}}}
                renderInput={(params) =>  
                  <CustomTextField 
                  sx={{ cursor: "pointer" }} 
                  {...params}
                  inputRef={(input: any) => {this.timePickerInputRefs[`endtime`] = input;}}
                />}
              />
            </LocalizationProvider>
            </Box>
            <Box  style={styles.flexContainer}
            sx={{cursor: "pointer"}}
            onClick={() => { this.timePickerInputRefs[`endtime`]?.click()}}
              data-test-id={`open-time-picker-endtime`}
            >
              <Image source={downArrow} style={styles.arrowStyle}
                />
            </Box></View> </View>
      </View>
    </>
  }

  renderTimezone() {
    const { showtimeZoneOverlay, timeZones, selectedTimeZone} = this.state
    return(         
      <View
        style={styles.dateContainer} data-test-id="dateContainer"
      >
        <TouchableOpacity  data-test-id="opentimeZoneoverlay"
          onPress={() => this.toggleShowtimeZoneOverlay()}>
          <View  style={styles.subContainer2} >
            <View style={{  alignContent: "center",flexDirection: "row", }}>
              <PublicIcon 
                  style={webStyle.icons} />
              <View style={{ marginLeft: 25 }}><Typography
                  style={{
                    fontWeight: "500",
                    fontSize: 16,
                    color: this.state.showtimeZoneOverlay
                      ? "#0074BF"
                      : "#0F172A",
                  }}
                >{this.state.selectedTimeZone}
                </Typography> </View>
            </View>
            <Box sx={{cursor: "pointer"}}>
            <Image  style={styles.arrowStyle}
              source={this.state.showtimeZoneOverlay ? upArrow : downArrow} />
            </Box>
           
          </View>
        </TouchableOpacity>
      {showtimeZoneOverlay
        ? timeZones.map((zone, index) => (
          <View  key={index}
            style={{ justifyContent: "center",
              backgroundColor:
                selectedTimeZone == zone
                  ? "#E0F2F8"
                  : "#fff",
                  width: "100%",
                  height: 50,
            }}
          >
            <TouchableOpacity key={index}><Typography data-test-id="selecttimeZone" style={styles.selectedZone} onClick={()=>this.handleSelectTimezone(zone,index)}>
                {zone} </Typography></TouchableOpacity>
          </View>
        ))
        : null}
      </View>
  )}

  renderNotifyMembers () {
    const { allMembers } = this.state;   
    return allMembers.map((item, index) => {
      const initials = item.name.split(" ").map((n: any[]) => n[0]).join("");
      return (
        <TouchableOpacity 
        key={index} style={styles.allCheck} 
        data-test-id={`toggleSelectMember-${index}`}  
        onPress={() => this.handleSelectMembers(item)}>
          <Image source={this.handleMemberCheckbox(item.id) ? checkedBox
                  : unCheckedBox} style={styles.imageStyle} 
                  />
          {item.image ? ( <Image source={{ uri: item.image }} style={styles.imageProfileStyle} />
          ) : ( <View style={[styles.initialsContainer, styles.imageProfileStyle]}> <Typography style={styles.initialsText}>{initials}</Typography>
            </View>
          )}
          <View> <Typography style={styles.memberName}>{item.name}</Typography> </View>
        </TouchableOpacity>
      ); });
  }

  renderSelectNotifyPeoples() {

    const { isNotifyContainerOpen, userSelectedMembers} = this.state;

    if(this.state.shouldNotifyAlluser) return
    return (
      <View style={styles.borderBottom}><TouchableOpacity
          style={styles.subContainer2}
          data-test-id="toggleNotifyUser"
          onPress={() => this.toggleNotifyUserContainer()}
        >
          <View style={styles.flexCenter}><GroupsOutlinedIcon style={webStyle.icons}/>
            <Typography
              style={{
                 
                  marginLeft:25,
                  color: this.getColorForNotifyUser(),
                  fontSize: 16,
                  fontWeight: "400",
              }}
            >
              {!isNotifyContainerOpen && userSelectedMembers && userSelectedMembers.length > 0 ? this.formatSelectedMembers(userSelectedMembers)
                    : "Select people to notify"}
            </Typography></View>
          <View>
             <Box sx={{cursor: "pointer"}}>
             <Image  style={styles.arrowStyle}
              source={this.state.isNotifyContainerOpen ? upArrow : downArrow}
            />
             </Box>
           
          </View>
        </TouchableOpacity>


        {isNotifyContainerOpen && (
          <View style={{ paddingVertical: 10, paddingHorizontal:5}}>
            <View style={styles.searchContainer}>
              <SearchOutlinedIcon style={webStyle.icons} />
              <CustomSearchField 
                placeholder="Search waitlist members" style={styles.searchPlaceholder}
              />
            </View>
            <TouchableOpacity
              style={styles.allCheck}  onPress={() => this.toggleSelectAllMembers()}  data-test-id = "select-all-members"
            > <Image source={this.handleAllCheckIconforMembers()}
                style={styles.imageStyle}
              />
              <Typography  style={styles.selectText}> Select the first 10 waitlist members
              </Typography>
            </TouchableOpacity> {this.renderNotifyMembers()}
          </View>
        )}
      </View>
    );
  }

  getStatusDetails = (status: string) => {
      switch (status) {
          case "booked":
              return {
                  icon: <Image source={booked} style={styles.imageStyle} />,
                  label: "Booked",
              };

          case "expired":
              return {
                  icon: <Image source={expired} style={styles.imageStyle} />,
                  label: "Expired",
              };

          case "completed":
              return {
                  icon: <Image source={completed} style={styles.imageStyle} />,
                  label: "Completed",
              };

          case "missed":
              return {
                  icon: <Image source={missed} style={styles.imageStyle} />,
                  label: "Missed",
              };

          case "canceled":
              return {
                  icon: <Image source={canceled} style={styles.imageStyle} />,
                  label: "Canceled",
              };

          default:
              return {
                  icon: <Image source={openForBooking} style={styles.imageStyle} />,
                  label: "Open for booking",
              };;
      }
  };

  getAlertIcon() {
    const { alertMsg } = this.state;
    return (alertMsg === "Availability updated successfully!" || alertMsg === "Availability deleted successfully!")
      ? greenTick
      : cross;
  }

  renderStatusView(){
    const { dpeAvailabilityStatus } = this.state
    return <Box style={webStyle.dialogContentForStatus}>
            <Box sx={webStyle.sections}>
              <Grid container spacing={3}
              alignItems={"end"}  >
                  <Grid item>
                      <Box style={webStyle.dpeAvailabilityStatus}>{this.getStatusDetails(dpeAvailabilityStatus ?? "")?.icon}
                      </Box>
                  </Grid>
                  <Grid item>
                      <Typography style={webStyle.title}>STATUS</Typography>
                      <Typography style={webStyle.subTitle}>
                          {this.getStatusDetails(dpeAvailabilityStatus ?? "")?.label}</Typography>
                  </Grid>
              </Grid>
            </Box>
          </Box>
  }

  renderDpeDetails(){
    return <Box sx={webStyle.sections}>
    <Grid container spacing={3}
    alignItems={"end"}  >
        <Grid item>
            <Box sx={webStyle.statusIcon}><img style={styles.dpeImageStyle} src={dpe_icon} /></Box>
        </Grid>
        <Grid item>
            <Typography style={webStyle.title}>DPE</Typography>
            <Box sx={{display:"flex", alignItems:"center"}}>
              <Image source={{ uri: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png" }} style={styles.imageProfileDpe} />
              <Typography style={webStyle.dpeName}> Katherine Evans</Typography>
            </Box>
        </Grid>
    </Grid>
  </Box>
  }

  renderAvailabilityDate(){
    const { dpeAvailabilityDate } = this.state
    return <Box sx={webStyle.sections}>
            <Grid  alignItems={"end"} spacing={3} width={"100%"} container >
            <Grid item>
                <Box sx={webStyle.statusIcon}><TodayOutlinedIcon style={webStyle.icons}/></Box>
            </Grid>
            <Grid item>
                <Typography style={webStyle.title}>DATE</Typography>
                <Typography style={webStyle.subTitle}>
                  {dpeAvailabilityDate}
                </Typography>
            </Grid>
        </Grid>
    </Box>
  }

  renderAvailabilityTime(){
    const { dpeTimeslot } = this.state
   return <Box sx={webStyle.sections}>
            <Grid  alignItems={"end"} spacing={3} width={"100%"} container >
              <Grid item>
                <Box sx={webStyle.statusIcon}><AccessTimeIcon style={webStyle.icons} /></Box>
              </Grid>
              <Grid item>
                <Typography style={webStyle.title}>TIME FRAME</Typography>
                <Typography style={webStyle.subTitle}>
                  {dpeTimeslot}
                </Typography>
              </Grid>
            </Grid>
        </Box>
  }

  renderTimezoneView(){
    const { dpeTimezone } = this.state
    return <Box sx={webStyle.sections}>
              <Grid width={"100%"} container 
              alignItems={"end"} spacing={3}>
                <Grid item>
                  <Box sx={webStyle.statusIcon}><PublicIcon style={webStyle.icons} /> </Box>
                </Grid>
                <Grid item>
                  <Typography style={webStyle.title}>TIME ZONE</Typography>
                  <Typography style={webStyle.subTitle}>{dpeTimezone}</Typography>
                </Grid>
              </Grid>
            </Box>
  }

  renderDpeSelectedAirports(){
    const { isDpeAirportSelectOpen, dpeAllAirport } = this.state
    return <>
              <Box sx={webStyle.selectContainer} data-test-id="select-airports" onClick={() => this.toggleDpeSelectAirport()}>
                <View style={styles.dpeAirportSelectContainer}>
                  <View style={styles.flexCenterView}>
                    <FlightTakeoffIcon style={webStyle.icons} />
                    <View style={{ marginLeft: 18 }}>
                      <Typography style={webStyle.title}>{this.getDpeAirportSelectLabelEdit()}</Typography>
                      <Typography  style={{fontSize: isDpeAirportSelectOpen ? 12 : 16 ,fontWeight: isDpeAirportSelectOpen ? "700" :"500",
                          color: this.getSelectedDpeAirportolorEdit(), }}>
                        {this.state.isDpeAirportSelectOpen ? this.getDpeAirportContainerPlaceholderEdit(): this.getAirportPlaceholderWhenClosed()}
                      </Typography>
                    </View>
                  </View>
                  <Box sx={{cursor: "pointer"}}>
                  <Image  style={[styles.arrowStyle, { tintColor: this.handleDpeAirportDrodownIconColor()}]} source={isDpeAirportSelectOpen ? upArrow : downArrow} />
                  </Box>
                </View>
              </Box>
              {isDpeAirportSelectOpen && <Box>
                {dpeAllAirport.length >= 1 &&
                  dpeAllAirport.map((item: any, index: number) => (
                    <Box sx={[webStyle.detailsContainer, {backgroundColor: this.handleDpeAirportBackgroundColor(item.service_subcategory_id)}]}>
                      <Box key={item.service_subcategory_id}
                      sx={webStyle.singleTest}
                      data-test-id={`dpe-select-airport-${index}`}
                      onClick={() => this.setState({depSelectedAirport: item})}
                    >
                      <Image  source={ this.handleDpeAirportCheckboxforedit(item.service_subcategory_id) ? radio_selected : radio_unselected} style={styles.checked} />
                      <Typography style={webStyle.airportText}>{item.service_subcategory_name} </Typography>
                    </Box>
                    </Box>
                  ))}
              </Box>}
    </>
  }

  renderDpeSelectedTests(){
    const {isDpeTestTypeOpen, dpeAllTest, depSelectedTests} = this.state
    return <>
    <Box sx={webStyle.selectContainer} data-test-id="select-dpe-tests" onClick={() => this.toggleDpeSelectTest()}>
      <View style={styles.dpeTestSelectContainer}>
        <View style={styles.flexCenterView}>
          <AirplaneTicketOutlinedIcon style={webStyle.icons} />
          <View style={{ marginLeft: 18 }}>
            <Typography style={webStyle.title}>{this.getDpeTestsSelectLabelEdit()}</Typography>
            <Typography  style={{fontSize: isDpeTestTypeOpen ? 12 : 16,fontWeight: isDpeTestTypeOpen ? "700":"500",
                color: this.getSelectedDpeTestColorEdit(), }}>
              {this.state.isDpeTestTypeOpen ? this.getDpeTestContainerPlaceholderEdit() : this.getDesignationPlaceholderWhenClosed()}
            </Typography>
            {!isDpeTestTypeOpen && depSelectedTests.id !== -1 && <CustomDetailsLink data-test-id="view-test-details" to="/calendar/test-details">Details</CustomDetailsLink> }
          </View>
        </View>
        <Box sx={{cursor: "pointer"}}>
        <Image  style={[styles.arrowStyle, { tintColor: this.handleDpeTestDrodownIconColor() }]} source={isDpeTestTypeOpen ? upArrow : downArrow} />
        </Box>
      </View>
    </Box>
    {isDpeTestTypeOpen && <Box>
      {dpeAllTest.length >= 1 &&
        dpeAllTest.map((item: any, index: number) => (
          <Box sx={[webStyle.detailsContainer, {backgroundColor: this.handleDpeTestBackgroundColor(item.service_subcategory_id)}]}>
            <Box key={item.service_subcategory_id}
            sx={webStyle.singleTest}
            data-test-id={`dpe-select-test-${index}`}
            onClick={() => this.setState({depSelectedTests: item})}
          >
            <Image  source={ this.handleDpeTestCheckboxforedit(item.service_subcategory_id) ? radio_selected : radio_unselected} style={styles.checked} />
            <Typography style={webStyle.airportText}>{item.service_subcategory_name} </Typography>
            </Box>
            <CustomDetailsLink data-test-id="view-test-details" to="/calendar/test-details">Details</CustomDetailsLink>
          </Box>
        ))}
    </Box>}
    </>
  }

  renderTermsView(){
    const { isTermsChecked, dpeAvailabilityStatus } = this.state
    if(this.getStatusDetails(dpeAvailabilityStatus ?? "").label === "expired") return ;
    return <View style={[styles.dpeTestSelectContainer,{ marginLeft:"41px",}]}>
    <View style={styles.flexDirectionRow}>
      <Box data-test-id="terms-checkbox" onClick={() => this.handleTermsCheck()}
          sx={[styles.selectAllAirport, {cursor:"pointer"}]} >
          <Image source={isTermsChecked ? checkedBox: unCheckedBox} style={styles.checked}
        /> 
      </Box>
      <View style={{ marginLeft: "20px", marginRight:"58px" }}>
        <Box>
        <Typography style={webStyle.terms}>I understand that all checklist items must be completed at the time of application for </Typography>
        <Typography style={webStyle.terms}>my practical test.If it is found that I am unprepared I will pay the examiner his/her</Typography>
        <Typography style={webStyle.terms}>entire fee for the wasted checkride spot.</Typography>
        </Box>
      </View>
      <CustomReviewLink style={webStyle.reviewLink} to="">Review checklist</CustomReviewLink>
    </View>
  </View>
  }

  renderActionButtons(){
    if(this.getStatusDetails(this.state.dpeAvailabilityStatus ?? "").label === "expired") return ;
    return <>
    <Box sx={{borderBottom: "1px solid #E2E8F0", marginTop:"10px"}}></Box>
    <Box sx={webStyle.subContainer4}>
      <StyledButtonContainer>
        <CustomButton
          data-test-id="cancel-book-availability"
          variant="outlined"
          className="dpeCancelViewButton"
          onClick={() => this.handleCancelBookAppointment()}>
            Cancel
        </CustomButton>
      </StyledButtonContainer>
      <StyledButtonContainer>
        <CustomButton
          data-test-id="book-availability"
          variant="contained"
          color="primary"
          className={this.shouldProceedToBookingAppointment() ? "bookNowButton" : "bookNowButtonDisabled"}
          onClick={() => this.proceedForBookingAppointment()}
          >
            Book now!
        </CustomButton>
      </StyledButtonContainer>
    </Box>
    </>
  }

  render() {
   
    const localizer = momentLocalizer(moment);
    const { selectedAvailability, selectedTimeSlotForEdit } = this.state
    const isNoEvents = this.state.events.length === 0 && (this.state.view === "day");

    console.log(this.state.availabilityDate)
    return (
      <div style={{width: '100%',position:'relative'}}>
        <Snackbar  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={this.oncloseAlert} open={this.state.isAlert}
          autoHideDuration={3000}
        data-test-id="alertTestId"
        ><CustomBoxSnackbar alertType={this.state.alertType as any}>
              <img   src={this.getAlertIcon()}  alt={"icon"}
                style={webStyle.snackbarImage as any}
              />{this.state.alertMsg}
          </CustomBoxSnackbar></Snackbar>
          {isNoEvents && (
        <div style={{
          position:'absolute',
          textAlign: "center",
          fontSize: "12px",
          fontWeight: 500,
          color: "#94A3B8",
          top:'94px',
          left:'64px',
          backgroundColor: "white",
          zIndex: 9999,
        }}>
          Nothing is planned.
        </div>
      )}
        <Calendar
       date={this.props.date}
          localizer={localizer}
          events={this.state.events}
          startAccessor="start"
          endAccessor="end"
          view={this.state.view}
          onView={(view) => this.setState({ view })}
            views={{
            month: true,   
            week: true,
            day: true,
            '3 day': CustomWeekView,
          } as any}
          components={{
            toolbar: this.CustomToolbar,
            event: ({ event }) => (
              <span
                data-test-id="view-availability"
                onClick={() => this.handleOpenEventDetails(event)}
              >
                {this.state.view === "week" ? (
                  <div
                    style={{
                      display: "grid",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>{event.user_name}</strong>
                    <div style={{marginTop:'2px'}}>{event.airport}</div>
                  </div>
                ) : ["day", "3 day"].includes(this.state.view) ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong>{event.user_name}</strong>
                    <div>{event.airport}</div>
                  </div>
                ) : (
                  <>
                    {event.title} | <strong>{event.user_name}</strong>
                  </>
                )}
              </span>
            )
            
       
          }}
          eventPropGetter={this.eventStyleGetter} 
          style={{ height: 500, backgroundColor: "white", borderRadius: "10px" }}
          data-test-id="startAccessor"
          messages={{
            showMore: (count) => `...` 
          }}
        />

          <Dialog open={this.state.isViewAvailabiliDialogOpen}
              onClose={() => this.setState({ isViewAvailabiliDialogOpen: false })} maxWidth="sm" fullWidth
              PaperProps={{ style: { borderRadius: 16, width: "100%" } }} >
              <CustomViewTitle>
                  Availability
                  <Box style={webStyle.iconsContainer}>
                      <IconButton
                          aria-label="close"
                          data-test-id="edit-icon"
                          onClick={() => this.setState({ isEditAvailabilityOpen: true, isViewAvailabiliDialogOpen: false })}
                      >
                          <EditOutlinedIcon style={styles.editIcon} />
                      </IconButton><IconButton
                          aria-label="close"
                          data-test-id="delete-icon"
                          onClick={()=> this.setState({ deleteAvailabilityDialogOpen: true })}
                      >
                          <DeleteOutlineIcon style={styles.editIcon} />
                      </IconButton>
                  </Box>
                  <IconButton
                      aria-label="close"
                      data-test-id="view-close-icon"
                      onClick={() => this.setState({ isViewAvailabiliDialogOpen: false })}
                      sx={{ position: 'absolute', right: 8, top: 16, }}
                  >
                      <CloseIcon sx={webStyle.icons} />
                  </IconButton>
              </CustomViewTitle>
              <Box style={webStyle.dialogContentForStatus}>
                  <Box sx={webStyle.sections}>
                      <Grid container alignItems={"end"} spacing={3} >
                          <Grid item>
                              <Box style={webStyle.availabilityStatus}>
                                  {this.getStatusDetails(selectedAvailability?.availability?.status as string)?.icon}
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>STATUS</Typography>
                              <Typography style={webStyle.subTitle}>
                                  {this.getStatusDetails(selectedAvailability?.availability?.status as string)?.label}</Typography>
                          </Grid>
                      </Grid>
                  </Box>
              </Box>
              <Box sx={webStyle.dialogContent}>
                  <Box sx={webStyle.sections}>
                      <Grid container alignItems={"end"} spacing={3}>
                          <Grid item>
                              <Box sx={webStyle.statusIcon}>
                                  <img style={styles.imageStyle} src={calender} />
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>DATE</Typography>
                              <Typography style={webStyle.subTitle}>
                                  {this.formatDate(this.state.availabilityDate as any)}
                                  </Typography>
                          </Grid>
                      </Grid>
                  </Box>
                  <Box sx={webStyle.sections}>
                      <Grid width={"100%"} container alignItems={"end"} spacing={3}>
                          <Grid item>
                              <Box sx={webStyle.statusIcon}>
                                  <AccessTimeIcon style={webStyle.icons} />
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>TIME FRAME</Typography>
                              <Typography style={webStyle.subTitle}>
                                  {selectedAvailability?.availability?.start_time} - {selectedAvailability?.availability?.end_time}
                              </Typography>
                          </Grid>
                      </Grid>
                  </Box>
                  <Box sx={webStyle.sections}>
                      <Grid width={"100%"} container alignItems={"end"} spacing={3}>
                          <Grid item>
                              <Box sx={webStyle.statusIcon}>
                                  <PublicIcon style={webStyle.icons} />
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>TIME ZONE</Typography>
                              <Typography style={webStyle.subTitle}>
                                  {selectedAvailability.availability?.time_zone}</Typography>
                          </Grid>
                      </Grid>
                  </Box>
                  <Box sx={webStyle.sections}>
                      <Grid width={"100%"} container alignItems={"end"} spacing={3}>
                          <Grid item>
                              <Box sx={webStyle.statusIcon}>
                                  <FlightTakeoffIcon style={webStyle.icons} />
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>AIRPORT</Typography>
                              <Typography style={webStyle.subTitle}>
                                  {this.getAirportContainerPlaceholderEdit()}</Typography>
                          </Grid>
                      </Grid>
                  </Box>
                  <Box sx={webStyle.sections}>
                      <Grid width={"100%"} container alignItems={"end"} spacing={3}>
                          <Grid item>
                              <Box sx={webStyle.statusIcon}>
                                  <AirplaneTicketOutlinedIcon style={webStyle.icons} />
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>TESTS</Typography>
                              <Typography style={webStyle.subTitle}>
                                  {this.getTestContainerPlaceholderEdit()}
                                  </Typography>
                          </Grid>
                      </Grid>
                  </Box>
                  <Box sx={webStyle.sections}>
                      <Grid width={"100%"} container alignItems={"end"} spacing={3}>
                          <Grid item>
                              <Box sx={webStyle.statusIcon}>
                                  <NotificationsOutlinedIcon style={webStyle.icons} />
                              </Box>
                          </Grid>
                          <Grid item>
                              <Typography style={webStyle.title}>NOTIFIED TO</Typography>
                              <Typography style={webStyle.subTitle}>
                                  James Cooper, Mia Sulivan</Typography>
                          </Grid>
                      </Grid>
                  </Box>
              </Box>
            </Dialog>

        <Dialog open={this.state.isEditAvailabilityOpen} 
            onClose={() => { 
              this.setState({ isEditAvailabilityOpen: false })
            }} maxWidth="sm" fullWidth PaperProps={{style: { borderRadius: 16, width: "100%" }}} >
              <CustomEditTitle>
                Edit availability
                <IconButton
                  data-test-id="close-icon-edit"
                  aria-label="close"
                  onClick={() => this.setState({ isEditAvailabilityOpen: false , isViewAvailabiliDialogOpen: true})}
                  sx={{ position: 'absolute', right: 18, top: 18, }}
                >
                  <CloseIcon sx={styles.closeIcon} />
                </IconButton>
              </CustomEditTitle>
              <DialogContent sx={styles.dialogContent} >
                {this.renderCalender()}
                {this.renderSingleTimeSlot( selectedTimeSlotForEdit, 0 as number, true)}
                {this.renderTimezone()}
                {this.renderSelectAirportView()}
                {this.renderSelectTestsView()}
                {this.renderNotifyAllSwitch()}
                {this.renderSelectNotifyPeoples()}

                <View style={styles.subContainer2}>
                  <StyledButtonContainer>
                    <CustomButton
                      data-test-id="cancel-edit"
                      variant="outlined"
                      className="cancelButton"
                      onClick={() => {
                        this.setState({isEditAvailabilityOpen: false, isViewAvailabiliDialogOpen: true})
                        this.handleCancelEdit()
                      }}>
                        Cancel
                    </CustomButton>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <CustomButton
                      data-test-id="update-details"
                      variant="contained"
                      className={this.shouldEnableSaveEditedAvailablityBtn() ? "setDetailsButton" : "saveButton"}
                      color="primary"
                      onClick={this.shouldEnableSaveEditedAvailablityBtn() ? () => this.editAvailabilityDetails() : undefined}
                      >
                       Done
                    </CustomButton>
                  </StyledButtonContainer>
                </View>
              </DialogContent>
          </Dialog>

          <CustomDialog open={this.state.deleteAvailabilityDialogOpen}
            onClose={() => { 
              this.setState({ deleteAvailabilityDialogOpen: false })
            }} maxWidth="sm" fullWidth PaperProps={{style: { borderRadius: 16, width: "500px", overlay:"none"}}} >
              <CustomDeleteTitle>
                <Image source={deleteIcon} style={styles.imageDeleteStyle} />
                 Delete availability?
                <IconButton
                  data-test-id="close-icon-delete"
                  aria-label="close"
                  onClick={() => this.setState({ deleteAvailabilityDialogOpen: false, isViewAvailabiliDialogOpen: true })}
                  sx={{ position: 'absolute', right: 8, top: 18, }}
                >
                  <CloseIcon sx={webStyle.icons} />
                </IconButton>
              </CustomDeleteTitle>
              <DialogContent sx={styles.deleteDialogContent} >
                <Box sx={webStyle.deleteText}>
                    <Typography>
                      Are you sure you want to remove this availability?
                    </Typography>
                    <Typography>This action cannot be undone.</Typography>
                </Box>
                <Box sx={styles.borderBottom}></Box>
                <View style={styles.subContainer3}>
                  <StyledButtonContainer>
                    <CustomButton
                      data-test-id="cancel-delete"
                      variant="outlined"
                      className="deleteCancelButton"
                      onClick={() => this.setState({deleteAvailabilityDialogOpen: false, isViewAvailabiliDialogOpen: true})}>
                        Cancel
                    </CustomButton>
                  </StyledButtonContainer>
                  <StyledButtonContainer>
                    <CustomButton
                      data-test-id="delete-availability"
                      variant="contained"
                      color="primary"
                      className="deleteButton"
                      onClick={() => this.handleDeleteAvailability()}
                      >
                        Yes, delete availability
                    </CustomButton>
                  </StyledButtonContainer>
                </View>
              </DialogContent>
          </CustomDialog>

          <Dialog open={this.state.isViewApplicantsAvailabilityDialogOpen} maxWidth="sm" fullWidth PaperProps={{ style: { borderRadius: 16, minWidth: "665px", overflowX:"hidden", scrollbarWidth:"thin" } }}
              onClose={() => this.handleCancelBookAppointment()} >
              <CustomViewTitle>
                  Availability
                  <IconButton
                    onClick={() => this.handleCancelBookAppointment()}
                    sx={{ position: 'absolute', right: 18, top: 16, }}
                    aria-label="close"
                    data-test-id="applicant-close-icon"
                  ><CloseIcon sx={webStyle.icons} /></IconButton>
              </CustomViewTitle>
              {this.renderStatusView()}
              <Box sx={webStyle.dialogContent}>
                  {this.renderDpeDetails()}
                  {this.renderAvailabilityDate()}
                  {this.renderAvailabilityTime()}
                  {this.renderTimezoneView()}
              </Box>
              {this.renderDpeSelectedAirports()}
              {this.renderDpeSelectedTests()}
              {this.renderTermsView() }
              {this.renderActionButtons()}
            </Dialog>
      </div>
    );
  }
}

const CustomDetailsLink = styled(Link)({
  color:"#0074BF",
  fontWeight: "600",
  fontSize: 14
})

const CustomReviewLink = styled(Link)({
  color:"#0074BF",
  fontWeight: "600",
  fontSize: 12
})

const CustomViewTitle = styled(DialogTitle)({
  borderBottom: "1px solid rgb(226, 232, 240)",
  paddingTop: "20px",
  fontSize: 22,
  fontWeight: "700",
  textAlign: 'left',
  color: "#001C55",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  padding: "16px 24px 16px 46px",
  justifyContent: "space-between"
})

const CustomBoxSnackbar = styled(Box)<CustomBoxSnackbarProps>(({ alertType }) => ({
  color: alertType === "success" ? "#0F172A" : "red",
  display: "flex",
  alignItems: "center",
  padding: "8px",
  borderRadius: "4px",
  backgroundColor: alertType === "success" ? "#D1FAE5" : "#FEE2E2", // Example background color
}));

const CustomTextField = styled(TextField)({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input":{
    cursor:"pointer",
  }
})

const CustomDeleteTitle = styled(DialogTitle)({
  border:"1px solid #E2E8F0",
  fontSize: 22,
  fontWeight: "700",
  textAlign: 'left',
  color: "#001C55",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  margin:"0px",
  padding:"16px 30px"
})

const CustomEditTitle = styled(DialogTitle)({
  borderBottom: "1px solid rgb(226, 232, 240)",
  paddingTop:"20px",
  fontSize: 22,
  fontWeight: "700",
  textAlign: 'left',
  color: "#001C55",
  fontFamily: "Montserrat",
  display: "flex",
  alignItems: "center",
  padding:"16px 24px 16px 35px",
  justifyContent:"space-between"
})

const CustomBox = styled(Box)({
  top: "100%",
  left: 300,
  zIndex: 1000,
  background: "#fff",
  borderRadius: "8px",
  padding: "10px"
})

const webStyle = {
  reviewLink:{width:"50px"},
  subContainer4:{ display:"flex", flexDirection: "row", justifyContent: "space-between",
    alignItems: "center", margin:"20px 45px 20px 45px"
  },
  selectContainer: {paddingBottom: "2px", cursor:"pointer"},
  detailsContainer: {display:"flex", justifyContent:"space-between", alignItems:"center", padding:"0px 20px 0px 29px", cursor:"pointer"},
  availabilityStatus: { width: 20, height: 20, paddingBottom: "2px" },
  dpeAvailabilityStatus: { width: "22px", height: "22px", paddingBottom: "3px" },
  dialogContent: { height: "inherit", padding: "0px 20px 0px 30px", marginTop: "10px", alignItems: "center" },
  sections: {
      padding: "2px 10px",
      height: "64px",
      width:"100%"
  },
  dialogContentForStatus: { height: "inherit", padding: "10px 20px 0px 30px", marginTop: "10px", alignItems: "center", borderBottom: "1px solid #E2E8F0" },
  iconsContainer: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      marginRight: "20px"
  },
  arrowStyle:{ height: "12px",
    width: "12px", color: "#94A3B8",
    marginBottom:"5px"
  },
  subTitle: {
      fontWeight: 400,
      color: "#475569",
      fontSize: 16,
  },
  dpeName: {
    fontWeight: 400,
    color: "#0074BF",
    fontSize: 16,
  },
  terms: {
    color: "#475569",
    fontSize: 12,
  },
  airportText:{
    fontWeight: "400",
    marginLeft: "20px",
    fontSize: 16,
    color: "#0F172A"
  },
  singleTest:{
    margin: "10px 12px",
    display:"flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkList:{display: "flex", alignItems:"center", justifyContent:"space-between"},
  title: {
      fontWeight: 700,
      color: "#94A3B8",
      fontSize: 12,
      fontFamily: "Montserrat",
  },
  statusIcon: { paddingBottom: "6px", width: "20px", height: "20px", },
  snackbarImage:{
    width: "20px", 
    height: "20px",
    marginRight: "8px", 
    verticalAlign: "middle",
    objectFit:'contain'
  },
  icons:{color:"#94A3B8", cursor: "pointer"},
  deleteText:{
    paddingBottom:"10px",
    paddingTop:"50px",
    padding:"40px",
    display:"flex",
    justifyContent:"center",
    flexDirection:"column",
    borderBottom:"1px solid #E2E8F0"
  },}

const CustomDialog = styled(Dialog)({
  "& .MuiModal-backdrop":{
    background:"rgba(255, 255, 255, 0.3)"
  }
})

const CustomSearchField = styled(TextField)({
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    padding:0
  }
})

const CustomButton = styled(Button)({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: 16,
  width: 258,
  borderRadius: 20
})

const StyledButtonContainer = styled(Box)({
  "& .saveButton": {
      textTransform: "none",
      opacity: "30%",
      borderRadius: "20px",
      padding: "0px",
      fontWeight: 700,
      fontFamily: "Montserrat",
      color: "#ffffff",
      background: "#0074BF",
      height: "50px",
      fontSize: "16px",
    },
  "& .setDetailsButton": {
      padding: "0px",
      fontWeight: 700,
      height: "50px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      textTransform: "none",
      color: "#ffffff",
      background: "#0074BF",
      borderRadius: "20px",
    },
  "& .deleteButton": {
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      height: "50px",
      fontSize: "16px",
      padding: "0px 15px",
      borderRadius: "20px",
      color: "#ffffff",
      width:"226px",
      background: "#0074BF",

    },
  "& .cancelButton": {
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      border:"2px solid #0074BF",
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      borderRadius: "20px",
      color:"#0074BF",
    },
  "& .dpeCancelViewButton": {
      fontWeight: 700,
      fontFamily: "Montserrat",
      textTransform: "none",
      border:"2px solid #0074BF",
      height: "50px",
      fontSize: "16px",
      padding: "0px",
      borderRadius: "20px",
      color:"#0074BF",
      width:"280px"
    },
  "& .bookNowButton": {
      padding: "0px",
      fontWeight: 700,
      height: "50px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      textTransform: "none",
      color: "#ffffff",
      background: "#0074BF",
      borderRadius: "20px",
      width:"280px",
      marginLeft:"15px"
  },
  "& .bookNowButtonDisabled": {
      padding: "0px",
      fontWeight: 700,
      height: "50px",
      fontSize: "16px",
      opacity:"30%",
      fontFamily: "Montserrat",
      textTransform: "none",
      color: "#ffffff",
      background: "#0074BF",
      borderRadius: "20px",
      width:"290px",
      marginLeft:"15px"
  },
  "& .deleteCancelButton": {
      padding: "0px",
      color:"#0074BF",
      height: "50px",
      fontFamily: "Montserrat",
      fontWeight: 700,
      width:"218px",
      textTransform: "none",
      fontSize: "16px",
      borderRadius: "20px",
      border:"2px solid #0074BF"
    },
})

const styles = StyleSheet.create({
  dateContainer :{
    width: "100%", borderBottomWidth: 1,borderBottomColor: "#E2E8F0",
  },
  timeSlots:{ width: "100%",
    borderBottomWidth: 1,borderBottomColor: "#E2E8F0",
    paddingVertical:"3px",
  },
  settings:{ flexDirection: "row", alignItems: "center",
    marginTop: 15, marginLeft:10
  },
  padding:{ paddingVertical: 10,
    paddingHorizontal: 20,
  },
  checked:{ width:20, height:20},
  selectAllTest:{
    flexDirection: "row", paddingVertical: 10,
    paddingHorizontal: 12,
    alignItems: "center",
  },
  subCate:{
    
    fontWeight: "400",
    marginLeft: "27px",
    fontSize: 16,
    color: "#0F172A",
  },
  eastIcon: {color:"#5274B5"},
  editIcon:{color:"#0074BF"},

  subContainer:{alignItems: "center",
    marginHorizontal: 30, flexDirection: "row",
    justifyContent: "space-between",
    display:"flex"
  },
  subContainer2:{
    marginTop:"20px",
    alignItems: "center",
    marginBottom:"20px",flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 30,
  },
  dpeAirportSelectContainer:{
    alignItems: "center",flexDirection: "row",
    justifyContent: "space-between",
    marginBottom:"5px",
    marginTop:"5px",
    marginLeft:"39px",
    marginRight: "24px"
  },
  dpeTestSelectContainer:{
    alignItems: "center",flexDirection: "row",
    justifyContent: "space-between",
    marginBottom:"5px",
    marginTop:"20px",
    marginLeft:"39px",
    marginRight: "24px"
  },
  subContainer3:{ flexDirection: "row",    justifyContent: "space-between",
    alignItems: "center",
    margin:"23px",
  },
  subContainerNotify:{
    marginVertical: 13,
    marginRight:18, flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft:30
  },
  timePickerContainer:{ flexDirection: "row",  alignItems: "center",  width: "220px"
  },
  flexContainer:{ display: "flex",alignItems: "center",justifyContent: "center",
  },
  arrowStyle:{ height: 12,
    width: 12,resizeMode: "contain", tintColor: "#94A3B8",
  },
  iconColor:{ color: "#5274B5" },
  deleteIcon:{ color: "#F87171" },
  timeslotTypo:{
    fontSize: 16,
    color: "#0074BF",
    fontWeight: "500",
  },
  flexCenterView:{ flexDirection: "row", alignItems: "flex-end" },
  flexCenter:{ flexDirection: "row",alignItems: "center" },
  flexDirectionRow:{
    flexDirection: "row"
  },
  notifyAll:{ color: "#475569",
    fontSize: 16,
    fontWeight: "500",
  },
  dialogTitle:{
    textAlign: 'left',
    color: "#001C55",display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat", borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
    fontSize: 22,
    fontWeight: "700",
    marginLeft:"5px"
  },
  closeIcon:{ width: "18px", 
    height: "18px", color: "#0074BF",
  },
  borderBottom:{ borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  dialogContent:{ marginTop: 0, height: "inherit", paddingTop:10, paddingLeft:0, paddingRight:0,},
  
  detailsContainer:{height: 40,width: "100%", backgroundColor: "#5274B51A",
    justifyContent: "center",
  },
  detailsText:{fontSize: 14,fontWeight: "700",lineHeight: 22,
    marginLeft: 30,
    color: "#5274B5",
    
  },
  calenderContainer:{ position: "relative" ,
    borderBottomColor: "#E2E8F0",
    borderBottomWidth: 1,
  },
  searchContainer:{ flexDirection: "row",  marginBottom: 10,
    alignItems: "center",paddingVertical: 5,
   
    paddingLeft: 16,
  },
  searchPlaceholder:{ marginLeft: 20,
    width: "90%",fontWeight: "400",fontSize: 16,
    
  },
  allCheck: {
    marginHorizontal:20, flexDirection: "row",alignItems: "center",
    marginVertical: 10,
  },
  memberName:{ fontWeight: "400",
    color: "#0F172A",
    marginLeft: 20, fontSize: 16,
    
  },
  leftArrow:{ marginRight: 25, marginLeft:8,
     height: '24px', width: '24px', 
     color: "#0074BF" },
  imageStyle:{height:"20px",
   width: "20px"},
  dpeImageStyle:{height:"22px", width: "22px"},
  imageDeleteStyle:{marginRight:"20px",height:40, 
    width:40, },
  deleteDialogContent:{ marginTop: 0, height: "inherit", padding:"0px"},
  imageProfileStyle: {borderRadius: 20,
    width: 25, height: 25,
    marginLeft:20
  },
  imageProfileDpe: {borderRadius: 20,
    width: "20px", height: "20px",
    marginRight:"15px"
  },
  initialsContainer: {alignItems: "center",
    color:"#0074BF",
    backgroundColor: "#5274B51A", justifyContent: "center", 
  },
  initialsText: { color: "#0074BF",fontSize: 12,
    fontWeight: "bold",
  },
  memberProfile:{height:20, width:20, borderRadius:50},
  selectText:{ fontSize: 16,
    fontWeight: "400",
    color: "#0F172A",
    marginLeft: 20,
    
  },
  selectedZone:{
    marginLeft: 80,
    color: "#0F172A",
    fontWeight: "500",
    textAlign: "justify",
    fontSize: 16, 
  },
  selectAirport:{paddingVertical: 10, paddingHorizontal: 32,borderBottomWidth: 1, borderBottomColor: "#E2E8F0", },
  selectAllAirport:{flexDirection: "row",
    marginVertical: 10, alignItems: "center",
  },
  editText:{
    marginLeft: 22, fontSize: 16,
    fontWeight: "500",color: "#0074BF",
  },
  editAirport:{
    fontWeight: "500",
    marginLeft: 23,
    fontSize: 16,
    color: "#0074BF",
  },
  allCheckImage:{
    resizeMode: "contain",height: 20,
    width: 20,
  },
  airportText:{
    fontWeight: "400",
    marginLeft: "27px",
    fontSize: 16,
    color: "#0F172A"
  },
  singleTest:{
    marginVertical: 10,
    flexDirection: "row",
    alignItems: "center",
    marginLeft:12
  }
})


// Customizable Area End
